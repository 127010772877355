import React from 'react';
import { Link } from 'react-router-dom';
import heroThumbOne from '../../assets/images/custom/peoples.png';

function SiapBergerakHomeOne() {
    return (
        <>
            <section className="appie-hero-area mitra">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                <span className='header mb-20'>Punya konsep dan sumber daya untuk membuat program sosial?</span><br/>
                                <p className='mb-20'>
                                    Dengan <span className='sb-yellow'>SIAP</span><span className='sb-blue'>BERGERAK</span>, temukan relawan yang siap untuk mendukung program sosial Anda.
                                </p>
                                <br/>
                                <Link to="/daftar-mitra"><button type="button" className='btn btn-primary btn-mitra'>Daftar Jadi Mitra</button></Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                    style={{margin: '50px -100px'}}
                                >
                                    <img src={heroThumbOne} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </section>
        </>
    );
}

export default SiapBergerakHomeOne;
