import React, { useEffect, useState } from 'react';
import { isValidEmail } from '../../utils/helper';
import LoadingOverlay from 'react-loading-overlay';

import { useGoogleLogin } from '@react-oauth/google';
import { fetchGet } from '../../services/fetch';

const googleClientId = '';

const Content = () =>  {
    const [methodId, setMethodId] = useState(0);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showFormDetail, setShowFormDetail] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        // <LoadingOverlay
        //     active={loading}
        //     spinner
        //     text='Harap Tunggu.'
        //     >
            <section className="contact-section">
                {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="WordSection1">

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:3'><b><span lang="EN-US" stylex='font-size:
                                13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>Privacy Policy</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>At siapbergerak.com,
                                accessible from https://siapbergerak.com, one of our main priorities is the
                                privacy of our visitors. This Privacy Policy document contains types of
                                information that is collected and recorded by siapbergerak.com and how we use
                                it.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>If you have additional
                                questions or require more information about our Privacy Policy, do not hesitate
                                to contact us.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>This Privacy Policy
                                applies only to our online activities and is valid for visitors to our website
                                with regards to the information that they shared and/or collect in siapbergerak.com.
                                This policy is not applicable to any information collected offline or via
                                channels other than this website.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>Consent</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>By using our website,
                                you hereby consent to our Privacy Policy and agree to its terms.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>Information we collect</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>The personal information
                                that you are asked to provide, and the reasons why you are asked to provide it,
                                will be made clear to you at the point we ask you to provide your personal
                                information.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>If you contact us
                                directly, we may receive additional information about you such as your name,
                                email address, phone number, the contents of the message and/or attachments you
                                may send us, and any other information you may choose to provide.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>When you register for an
                                Account, we may ask for your contact information, including items such as name,
                                company name, address, email address, and telephone number.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>How we use your information</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>We use the information
                                we collect in various ways, including to:</span></p>

                                <ul>
                                <li className="MsoNormal" stylex='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
                                    auto;line-height:normal;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
                                    lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                    mso-fareast-font-family:"Times New Roman"'>Provide, operate, and maintain
                                    our <span className="SpellE">webste</span></span></li>
                                <li className="MsoNormal" stylex='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
                                    auto;line-height:normal;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
                                    lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                    mso-fareast-font-family:"Times New Roman"'>Improve, personalize, and
                                    expand our <span className="SpellE">webste</span></span></li>
                                <li className="MsoNormal" stylex='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
                                    auto;line-height:normal;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
                                    lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                    mso-fareast-font-family:"Times New Roman"'>Understand and analyze how you
                                    use our <span className="SpellE">webste</span></span></li>
                                <li className="MsoNormal" stylex='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
                                    auto;line-height:normal;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
                                    lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                    mso-fareast-font-family:"Times New Roman"'>Develop new products, services,
                                    features, and functionality</span></li>
                                <li className="MsoNormal" stylex='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
                                    auto;line-height:normal;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
                                    lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                    mso-fareast-font-family:"Times New Roman"'>Communicate with you, either
                                    directly or through one of our partners, including for customer service,
                                    to provide you with updates and other information relating to the <span
                                    className="SpellE">webste</span>, and for marketing and promotional purposes</span></li>
                                <li className="MsoNormal" stylex='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
                                    auto;line-height:normal;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
                                    lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                    mso-fareast-font-family:"Times New Roman"'>Send you emails</span></li>
                                <li className="MsoNormal" stylex='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
                                    auto;line-height:normal;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><span
                                    lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                    mso-fareast-font-family:"Times New Roman"'>Find and prevent fraud</span></li>
                                </ul>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>Log Files</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>siapbergerak.com follows
                                a standard procedure of using log files. These files log visitors when they
                                visit websites. All hosting companies do this and a part of hosting services'
                                analytics. The information collected by log files include internet protocol
                                (IP) addresses, browser type, Internet Service Provider (ISP), date and time
                                stamp, referring/exit pages, and possibly the number of clicks. These are not
                                linked to any information that is personally identifiable. The purpose of the
                                information is for analyzing trends, administering the site, tracking users'
                                movement on the website, and gathering demographic information.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>Cookies and Web Beacons</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>Like any other website, siapbergerak.com
                                uses 'cookies'. These cookies are used to store information including visitors'
                                preferences, and the pages on the website that the visitor accessed or visited.
                                The information is used to optimize the users' experience by customizing our
                                web page content based on visitors' browser type and/or other information.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>Advertising Partners Privacy Policies</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>You may consult this
                                list to find the Privacy Policy for each of the advertising partners of siapbergerak.com.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>Third-party ad servers
                                or ad networks uses technologies like cookies, JavaScript, or Web Beacons that
                                are used in their respective advertisements and links that appear on siapbergerak.com,
                                which are sent directly to users' browser. They automatically receive your IP
                                address when this occurs. These technologies are used to measure the
                                effectiveness of their advertising campaigns and/or to personalize the
                                advertising content that you see on websites that you visit.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>Note that siapbergerak.com
                                has no access to or control over these cookies that are used by third-party
                                advertisers.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>Third Party Privacy Policies</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span className="SpellE"><span lang="EN-US" stylex='font-size:13.5pt;
                                font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>siapbergerak.com's</span></span><span lang="EN-US" stylex='font-size:
                                13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'> Privacy Policy does not apply to other advertisers or websites.
                                Thus, we are advising you to consult the respective Privacy Policies of these third-party
                                ad servers for more detailed information. It may include their practices and
                                instructions about how to opt-out of certain options.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>You can choose to
                                disable cookies through your individual browser options. To know more detailed
                                information about cookie management with specific web browsers, it can be found
                                at the browsers' respective websites.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>CCPA Privacy Rights (Do Not Sell My Personal Information)</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>Under the CCPA, among
                                other rights, California consumers have the right to:</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>Request that a business
                                that collects a consumer's personal data disclose the categories and specific
                                pieces of personal data that a business has collected about consumers.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>Request that a business
                                delete any personal data about the consumer that a business has collected.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>Request that a business
                                that sells a consumer's personal data, not sell the consumer's personal data.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>If you make a request,
                                we have one month to respond to you. If you would like to exercise any of these
                                rights, please contact us.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>GDPR Data Protection Rights</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>We would like to make
                                sure you are fully aware of all of your data protection rights. Every user is
                                entitled to the following:</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>The right to access –
                                You have the right to request copies of your personal data. We may charge you a
                                small fee for this service.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>The right to
                                rectification – You have the right to request that we correct any information
                                you believe is inaccurate. You also have the right to request that we complete
                                the information you believe is incomplete.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>The right to erasure –
                                You have the right to request that we erase your personal data, under certain
                                conditions.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>The right to restrict
                                processing – You have the right to request that we restrict the processing of
                                your personal data, under certain conditions.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>The right to object to
                                processing – You have the right to object to our processing of your personal
                                data, under certain conditions.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>The right to data
                                portability – You have the right to request that we transfer the data that we
                                have collected to another organization, or directly to you, under certain
                                conditions.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>If you make a request,
                                we have one month to respond to you. If you would like to exercise any of these
                                rights, please contact us.</span></p>

                                <p className="MsoNormal title" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal;mso-outline-level:2'><b><span lang="EN-US" stylex='font-size:
                                18.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                                color:black'>Children's Information</span></b></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>Another part of our
                                priority is adding protection for children while using the internet. We
                                encourage parents and guardians to observe, participate in, and/or monitor and
                                guide their online activity.</span></p>

                                <p className="MsoNormal" stylex='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                                line-height:normal'><span lang="EN-US" stylex='font-size:13.5pt;font-family:"Times New Roman",serif;
                                mso-fareast-font-family:"Times New Roman";color:black'>siapbergerak.com does
                                not knowingly collect any Personal Identifiable Information from children under
                                the age of 13. If you think that your child provided this kind of information
                                on our website, we strongly encourage you to contact us immediately and we will
                                do our best efforts to promptly remove such information from our records.</span></p>

                                <p className="MsoNormal"></p>

                            </div>
                        </div>
                    </div>
                </div>
                {/* </GoogleOAuthProvider> */}
            </section>
        // </LoadingOverlay>
    );
}

export default Content;