import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/custom/logo.png';
import iconWa from '../../assets/images/custom/wa.svg';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p style={{whiteSpace: "nowrap", marginBottom: 0, paddingBottom: 0}}>
                                    Copyright &copy; 2022 PT. Kami Siap Bergerak.
                                </p>
                                <p style={{marginTop: 0, paddingTop: 0}}>
                                    All rights reserved.
                                </p>
                                <p>
                                    Follow kami di
                                </p>
                                <div className='sb-social text-center'>
                                    <i className="fab fa-instagram"/><div className='text'>SiapBergerak.id</div>
                                </div>
                                {/* <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Halaman Lainnya</h4>
                                <ul>
                                    <li>
                                        <Link to="/">Halaman Utama</Link>
                                    </li>
                                    {/* <li>
                                        <a href="#">Pertanyaan Seputar Vaksinasi Merdeka</a>
                                    </li> */}
                                    <li>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Hubungi Kami</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-envelope" /> admin@siapbergerak.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-phone" /> +(21) 342 762 44
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i><img src={iconWa}/></i> +62 812-1155-7575
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" /> Jalam Mataram No.4, Kel. Selong, Kec. Kebayoran Baru, Kota Adm. Jakarta Selatan, Provinsi DKI Jakarta
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-apple" /> Download for iOS
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-google-play" /> Download for
                                                Android
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2022 SiapBergerak. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
