import React from 'react';
import imgHands from '../../assets/images/custom/mission-hands.png';
import imgLoves from '../../assets/images/custom/mission-loves.png';

function MissionHomeOne() {
    return (
        <>
            <section className="appie-mission-area pt-20 pb-40">
                <div className="container">
                    <div className="row pb-60">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className='card'>
                                <div className="section-title">
                                    <h2 style={{margin: '50px 0 50px'}}>Terobosan digitalisasi sistem kerelawanan.</h2>
                                    <p>Menghubungkan Relawan, dermawan, dan mitra yang membutuhkan dalam bentuk aksi nyata.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className='card card-grey'>
                                <div className='header'>
                                    <img src={imgHands}/>
                                </div>
                                <div className="section-title">
                                    <h3 style={{margin: '20px 0px'}}>Berkontribusi nyata untuk Indonesia</h3>
                                    <p>Pilih dari berbagai misi sosial yang tersedia di sekitarmu.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className='card card-grey'>
                                <div className='header'>
                                    <img src={imgLoves}/>
                                </div>
                                <div className="section-title">
                                    <h3 style={{margin: '20px 0'}}>Wujudkan misi sosialmu bersama ribuan relawan</h3>
                                    <p>Dapatkan dukungan dari ribuan relawan yang <span style={{fontWeight: 'bold'}}>SiapBergerak</span> untuk mewujudkan misi sosialmu.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </section>
        </>
    );
}

export default MissionHomeOne;
