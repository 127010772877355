import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { checkToken, deactivateToken } from '../../../services/auth-service';
import { getDistricts, getOccupations, getProvinces, getRegencies, getVillages, uploadFile } from '../../../services/master-data';
import { saveOccupations, saveRelawan } from '../../../services/relawan-service';
import LoadingOverlay from 'react-loading-overlay';

const Forms = () =>  {
    const { token } = useParams();
    const [tokens, setTokens] = useState(null);

    const [done, setDone] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        fullname: '',
        nik: '',
        email: '',
        phone: '',
        address: '',
        province_id: 0,
        regency_id: 0,
        district_id: 0,
        village_id: 0,
        occupations: [{id: 0, certificate: null}],
        ktp_file: null
    });

    const [occupations, setOccupations] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [regencies, setRegencies] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [villages, setVillages] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [error]);

    useEffect(() => {
        fetchOccupations();
        fetchProvinces();
        validateToken();
    }, []);

    useEffect(() => {
        if (formData.province_id !== 0) {
            setRegencies([]);
            formData.regency_id = 0;

            setDistricts([]);
            formData.district_id = 0;

            setVillages([]);
            formData.village_id = 0;

            fetchRegencies(formData.province_id);
        }
    }, [formData.province_id]);

    useEffect(() => {
        if (formData.regency_id !== 0) {
            setDistricts([]);
            formData.district_id = 0;

            setVillages([]);
            formData.village_id = 0;

            fetchDistricts(formData.regency_id);
        }
    }, [formData.regency_id]);

    useEffect(() => {
        if (formData.district_id !== 0) {
            setVillages([]);
            formData.village_id = 0;

            fetchVillages(formData.district_id);
        }
    }, [formData.district_id]);

    useEffect(() => {
        if (tokens && tokens.length > 0) {
            setFormData({ ...formData, phone: tokens[0].phone, email: tokens[0].email, fullname: tokens[0].google_name })
        }
    }, [tokens]);

    const validateToken = () => {
        checkToken(token).then(resp => {
            setTokens(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchOccupations = () => {
        getOccupations().then(resp => {
            setOccupations(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchProvinces = () => {
        getProvinces().then(resp => {
            setProvinces(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchRegencies = (provinceCode) => {
        getRegencies(provinceCode).then(resp => {
            setRegencies(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchDistricts = (regencyCode) => {
        getDistricts(regencyCode).then(resp => {
            setDistricts(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchVillages = (districtCode) => {
        getVillages(districtCode).then(resp => {
            setVillages(resp);
        }, error => {
            console.log(error);
        })
    }

    const addOccupation = (event) => {
        setFormData({...formData, occupations: [...formData.occupations, { id: 0 }]});
    }

    const onSelectFileKtp = (event) => {
        setFormData({...formData, ktp_file: event.target.files[0]});
    }
    const uploadKtp = () => {
        setLoading(true);
        setDone(false);
        setSuccess(false);
        setError(false);

        uploadFile(formData.ktp_file).then(resp => {
            console.log(resp);
            if (resp) {
                onSubmit(resp);
            }
        }, error => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(error.data?.message ?? "Unknown error");
        })
    }
    const uploadCertificates = (relawanId, cleanCertificate = [], index = 0) => {
        const data = { ...formData.occupations[index] };
        data.relawan_id = relawanId;
        data.occupation_id = formData.occupations[index].id;
        delete data.id;

        if (data.certificate) {
            uploadFile(data.certificate).then(resp => {
                console.log(resp);
                if (resp) {
                    data.certificate_file_id = resp;
                    delete data.certificate;
                    cleanCertificate.push(data);

                    if ((index+1) < formData.occupations.length) {
                        uploadCertificates(relawanId, cleanCertificate, index+1);
                    } else {
                        saveCertificates(cleanCertificate);
                    }
                }
            }, error => {
                console.log(error);
                setDone(true);
                setLoading(false);
                setError(error.data?.message ?? "Unknown error");
            })
        } else {
            delete data.certificate;
            cleanCertificate.push(data);

            if ((index+1) < formData.occupations.length) {
                uploadCertificates(relawanId, cleanCertificate, index+1);
            } else {
                saveCertificates(cleanCertificate);
            }
        }
    }
    const saveCertificates = (certificates) => {
        saveOccupations(certificates).then(resp => {
            console.log("certificates", resp);
            if (resp.length === certificates.length) {
                deactivateToken(token).then(resp => {
                    setDone(true);
                    setLoading(false);
                    setSuccess(true);
                }, error => {
                    console.log(error);
                    setDone(true);
                    setLoading(false);
                    setError(error.data?.message ?? "Unknown error");
                })
            }
        }, error => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(error.data?.message ?? "Unknown error");
        })
    }

    const onSubmit = (ktp_file_id) => {
        const data = { ...formData };
        data.ktp_file_id = ktp_file_id;

        delete data.ktp_file;
        delete data.occupations;
        delete data.province_id;
        delete data.regency_id;
        delete data.district_id;

        if (tokens && tokens.length > 0) {
            data.google_id = tokens[0].google_id;
            data.method_id = tokens[0].method_id;
        }

        saveRelawan(data).then(resp => {
            console.log("relawan", resp);
            if (resp && resp.length > 0) {
                if (formData.occupations.length > 0) {
                    uploadCertificates(resp[0].id);
                } else {
                    deactivateToken(token).then(resp => {
                        setDone(true);
                        setLoading(false);
                        setSuccess(true);
                    }, error => {
                        console.log(error);
                        setDone(true);
                        setLoading(false);
                        setError(error.data?.message ?? "Unknown error");
                    })
                }
            }
        }, error => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(error.data?.message ?? "Unknown error");
        })
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Data sedang dikirim. Harap Tunggu.'
            >
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            { tokens === null && <div className="contact-form">
                                Harap tunggu...
                            </div> }
                            { tokens && tokens.length === 0 && <div className="contact-form">
                                <h4 className='text-danger'>Link sudah kadaluarsa!</h4>
                                <p className='text-danger' style={{margin:0}}>Silakan daftar ulang untuk mendapatkan link registrasi baru</p>
                            </div> }
                            { done && success && <div className="contact-form">
                                <h4 className='text-success'>Pendaftaran berhasil!</h4>
                                <p className='text-success' style={{margin:0}}>Data Anda akan kami verifikasi terlebih dahulu.</p>
                            </div> }
                            { done && error && <div className="contact-form">
                                <h4 className='text-danger'>Pendaftaran gagal!</h4>
                                <p className='text-danger' style={{margin:0}}>{error}</p>
                            </div> }
                            { tokens && tokens.length === 1 && (!done || (done && error)) && <div className="contact-form">
                                <h4>Akun terverifikasi!</h4>
                                <p>Silakan isi form di bawah ini untuk daftar sebagai relawan</p>
                                
                                <div className="row">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.fullname}
                                            placeholder="Nama Lengkap"
                                            onChange={(event) => setFormData({...formData, fullname: event.target.value})}
                                            disabled={tokens && tokens[0].method_id === 1}
                                        />
                                        <div className='error-container'>
                                            { !formData.fullname && <div className='text-error'>Nama lengkap tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="nik"
                                            length={16}
                                            maxLength={16}
                                            value={formData.nik}
                                            placeholder="NIK"
                                            onChange={(event) => setFormData({...formData, nik: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.nik && <div className='text-error'>NIK tidak boleh kosong</div> }
                                            { formData.nik && formData.nik.trim().length != 16 && <div className='text-error'>NIK harus 16 digit</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            placeholder="Alamat Email"
                                            onChange={(event) => setFormData({...formData, email: event.target.value})}
                                            disabled={tokens && tokens[0].email}
                                        />
                                        <div className='error-container'>
                                            { !formData.email && <div className='text-error'>Email tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone"
                                            value={formData.phone}
                                            placeholder="Nomor Telepon"
                                            onChange={(event) => setFormData({...formData, phone: event.target.value})}
                                            disabled={tokens && tokens[0].phone}
                                        />
                                        <div className='error-container'>
                                            { !formData.phone && <div className='text-error'>Nomor telepon tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="address"
                                            value={formData.address}
                                            placeholder="Alamat Rumah - Nama Jalan sampai RT/RW"
                                            onChange={(event) => setFormData({...formData, address: event.target.value})}
                                        ></textarea>
                                        <div className='error-container'>
                                            { !formData.address && <div className='text-error'>Alamat tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <select
                                            name="provinsi"
                                            placeholder="Provinsi"
                                            value={formData.province_id}
                                            onChange={(event) => {
                                                setFormData({...formData, province_id: event.target.value});
                                            }}
                                        >
                                            <option value={0}>-- Pilih Provinsi --</option>
                                            { provinces.map((o, i) => { 
                                                return <option key={i} value={o.code}>{o.name}</option> 
                                            })}
                                        </select>
                                        <div className='error-container'>
                                            { !formData.province_id && <div className='text-error'>Provinsi tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <select
                                            name="regency"
                                            placeholder="Kota/Kabupaten"
                                            value={formData.regency_id}
                                            onChange={(event) => {
                                                setFormData({...formData, regency_id: event.target.value});
                                            }}
                                        >
                                            <option value={0}>-- Pilih Kota/Kabupaten --</option>
                                            { regencies.map((o, i) => { 
                                                return <option key={i} value={o.code}>{o.name}</option> 
                                            })}
                                        </select>
                                        <div className='error-container'>
                                            { !formData.regency_id && <div className='text-error'>Kota/kabupaten tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <select
                                            name="district"
                                            placeholder="Kecamatan"
                                            value={formData.district_id}
                                            onChange={(event) => {
                                                setFormData({...formData, district_id: event.target.value});
                                            }}
                                        >
                                            <option value={0}>-- Pilih Kecamatan --</option>
                                            { districts.map((o, i) => { 
                                                return <option key={i} value={o.code}>{o.name}</option> 
                                            })}
                                        </select>
                                        <div className='error-container'>
                                            { !formData.district_id && <div className='text-error'>Kecamatan tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <select
                                            name="village"
                                            placeholder="Kelurahan/Desa"
                                            value={formData.village_id}
                                            onChange={(event) => {
                                                setFormData({...formData, village_id: event.target.value});
                                            }}
                                        >
                                            <option value={0}>-- Pilih Kelurahan/Desa --</option>
                                            { villages.map((o, i) => { 
                                                return <option key={i} value={o.code}>{o.name}</option> 
                                            })}
                                        </select>
                                        <div className='error-container'>
                                            { !formData.village_id && <div className='text-error'>Kelurahan/desa tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            type="text"
                                            name="education"
                                            placeholder="Pendidikan Terakhir"
                                            value={formData.education}
                                            onChange={(event) => setFormData({...formData, education: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.education && <div className='text-error'>Pendidikan terakhir tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    { formData.occupations.map((occ, i) => { 
                                        return <div className="col-md-12" key={i}>
                                            <div style={{padding: '10px', marginBottom: '10px', border:'solid thin #d0d0d0'}}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h5>Profesi { formData.occupations.length > 1 ? (i+1) : ''}</h5>
                                                    </div>
                                                    { formData.occupations.length > 1 && <div className="col-md-6 text-right">
                                                        <button 
                                                            className='btn btn-danger pull-right btn-sm' 
                                                            onClick={() => {
                                                                formData.occupations.splice(i, 1);
                                                                setFormData({ ...formData });
                                                            }}><i className='fal fa-times'></i>
                                                        </button>
                                                    </div> }
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <select
                                                                name="profesi"
                                                                placeholder="Pekerjaan/Profesi"
                                                                style={{marginTop: '10px'}}
                                                                value={formData.occupations[i].id}
                                                                onChange={(event) => {
                                                                    formData.occupations[i].id = +event.target.value;
                                                                    setFormData({ ...formData });
                                                                }}
                                                            >
                                                                <option value={0}>-- Pilih Profesi --</option> 
                                                                { occupations.map((o, i) => { 
                                                                    return <option key={i} value={o.id} selected={occ.id === o.id}>{o.name}</option> 
                                                                })}
                                                        </select>
                                                        <div className='error-container'>
                                                            { !formData.occupations[i].id && <div className='text-error'>Profesi tidak boleh kosong</div> }
                                                        </div>
                                                        <div className='upload-container'>
                                                            <label htmlFor="upload"><b>Upload Sertifikat Profesi</b> (file harus dalam bentuk .jpg atau .png)</label><br/>
                                                            <input 
                                                                type={'file'} 
                                                                name="certificate" 
                                                                onChange={(event) => {
                                                                    formData.occupations[i].certificate = event.target.files[0];
                                                                    setFormData({...formData});
                                                                }} 
                                                                accept='.png,.jpg'/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                    <div className="col-md-12 text-center">
                                        <input type="button" name="addOcc" value="Tambah Profesi" onClick={addOccupation} style={{marginBottom: '20px'}}/>
                                    </div>
                                    <div className="col-md-12">
                                        <div className='upload-container'>
                                            <label htmlFor="upload"><b>Upload KTP</b> (file harus dalam bentuk .jpg atau .png)</label><br/>
                                            <input type={'file'} name="ktp" accept='.png,.jpg' onChange={onSelectFileKtp}/>
                                            <div className='error-container'>
                                                { !formData.ktp_file && <div className='text-error'>File KTP tidak boleh kosong</div> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <hr/>
                                        <div className="condition-check" style={{marginTop: '20px'}}>
                                            {/* <input id="terms-conditions" type="checkbox" />
                                            <label htmlFor="terms-conditions">
                                                I agree to the <a href="#">Terms & Conditions</a>
                                            </label> */}
                                            <input type="button" name="submit" onClick={uploadKtp} value="Daftar" style={{marginLeft: '20px'}}/>
                                        </div>
                                    </div>
                                </div>
                            </div> }
                        </div>
                    </div>
                </div>
            </section>
        </LoadingOverlay>
    );
}

export default Forms;
