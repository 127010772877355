import React from 'react';
import imgDownload from '../../assets/images/custom/download.png';
// import imgAndroid from '../../assets/images/custom/android.png';
// import imgIos from '../../assets/images/custom/ios.png';
// import imgLogo from '../../assets/images/custom/logo.png';
// import imgPlaystore from '../../assets/images/custom/playstore.png';

function DownloadHomeOne() {
    return (
        <>
            <section className="appie-mission-area pt-0 pb-40">
                <div className="container">
                    <div className="row pb-40">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                            <img src={imgDownload} width='100%' style={{maxWidth:'1280px'}}/>
                            {/* <div className='card card-download' style={{position: 'absolute', bottom:'0', height: '100px', width: '100%'}}></div>
                            <div className='row'>
                                <div className='col-md-4' style={{height:'350px'}}><img src={imgPlaystore} height='350px' style={{margin: '0px 60px'}}/></div>
                                <div className='col-md-4' style={{height:'150px'}}><img src={imgLogo} height='120px' style={{marginRight: '60px', marginTop:'190px'}}/></div>
                                <div className="col-md-4 section-title" style={{marginBottom: 'auto', height:'150px', marginTop:'200px'}}>
                                    <p>Segera hadir di</p>
                                    <ul style={{display:'flex', marginTop:'20px'}}>
                                        <li>
                                            <a href="#">
                                                <img src={imgAndroid} height="50px"/>
                                            </a>
                                        </li>
                                        <li style={{paddingLeft: '5px'}}>
                                            <a href="#">
                                                <img src={imgIos} height="50px"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <hr/>
                </div>
            </section>
        </>
    );
}

export default DownloadHomeOne;
