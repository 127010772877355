import { fetchDelete, fetchGet, fetchPatch, fetchPost } from "./fetch";

export const saveMitra = async (data) => {
  return await fetchPost("data/mitra", data, true, {
    prefer: "return=representation"
  });
};
export const updateMitra = async (id, data) => {
  return await fetchPatch("/data/mitra?id=eq."+id, data, true, {
    prefer: "return=representation"
  });
};
export const getDetailMitra = async (id) => {
  const select = [
    "*",
    "mou:fk_mitra_mou_file(id, public_id)",
    "concept:fk_mitra_concept_file(id, public_id)",
  ];
  var params = "?select="+select.join(',')+"&status=eq.3&public_id=eq."+id;
  return await fetchGet("data/mitra"+params);
};

export const checkEmailMitra = async (email) => {
  const select = [
    "id",
    "email"
  ];
  var params = "?select="+select.join(',')+"&email=eq."+encodeURIComponent(email);
  return await fetchGet("data/users"+params);
};