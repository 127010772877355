import React, { useState, useEffect } from 'react';
import heroThumbOne from '../../assets/images/custom/volunteer.png';
import logo from '../../assets/images/custom/logo.png';
import imgAndroid from '../../assets/images/custom/android.png';
import imgIos from '../../assets/images/custom/ios.png';
import LoadingOverlay from 'react-loading-overlay';

const SiapBergerakHomeOne = () => {

    const [loadingAppleData, setLoadingAppleData] = useState(false);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get("code")) {
            const host = window.location.protocol + "//" + window.location.host;
            window.location.replace(`${host}/daftar-relawan?code=${query.get("code")}`);
        }
    }, []);

    return (
        <LoadingOverlay
            active={loadingAppleData}
            spinner
            text={ loadingAppleData ? 'Loading Apple Data. Harap Tunggu.' : 'Harap Tunggu.' }
            >
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                <span className='header'>Selamat Datang di</span><br/>
                                <img src={logo} height='100px' className='logo'/>
                                <p>
                                    Kami adalah platform ruang sosial yang mempertemukan relawan dan dermawan yang Siap Bergerak dalam aksi kemanusiaan.
                                </p>
                                <br/>
                                <p>
                                    Segera hadir di
                                </p>
                                <ul style={{marginBottom: '30px'}}>
                                    <li>
                                        <a href="#">
                                            <img src={imgAndroid} height="50px"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={imgIos} height="50px"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={heroThumbOne} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </section>
        </LoadingOverlay>
    );
}

export default SiapBergerakHomeOne;
