import { fetchDelete, fetchGet, fetchPatch, fetchPost } from "./fetch";

export const registerRelawan = async (data) => {
  return await fetchPost("data/rpc/register_relawan", data, true, {
    prefer: "params=single-object"
  });
};
export const saveRelawan = async (data) => {
  if (data.id) {
    return await updateRelawan(data.id, data);
  } else {
    return await fetchPost("data/relawan", data, true, {
      prefer: "return=representation"
    });
  }
};
export const updateRelawan = async (id, data) => {
  const nData = { ...data, status: 0 };
  delete nData.id;
  return await fetchPatch("/data/relawan?id=eq."+id, nData, true, {
    prefer: "return=representation"
  });
};
export const deleteCertificates = async (relawanId) => {
  return await fetchDelete("/data/relawan_occupation?relawan_id=eq."+relawanId);
};
export const deleteFieldOfInterests = async (relawanId) => {
  return await fetchDelete("/data/relawan_field_of_interest?relawan_id=eq."+relawanId);
};
export const deleteMasteredLanguages = async (relawanId) => {
  return await fetchDelete("/data/relawan_mastered_language?relawan_id=eq."+relawanId);
};
export const saveOccupations = async (data) => {
  return await fetchPost("data/relawan_occupation", data, true, {
    prefer: "return=representation"
  });
};
export const saveFieldOfInterests = async (data) => {
  return await fetchPost("data/relawan_field_of_interest", data, true, {
    prefer: "return=representation"
  });
};
export const saveMasteredLanguages = async (data) => {
  return await fetchPost("data/relawan_mastered_language", data, true, {
    prefer: "return=representation"
  });
};
export const getDetailRelawan = async (id) => {
  const select = [
    "*",
    "relawan_occupation(occupation(id,name),files(id,public_id))",
    // "registration_method(id,name)",
    "ktp:fk_relawan_ktp_file(id, public_id)",
    "insurance:fk_relawan_insurance_file(id, public_id)",
    "member_card:fk_relawan_member_card_file(id, public_id)",
    // "relawan_status(id,name)",
    "area_village(code, area_district(code, area_regency(code, area_province(code))))",
    "relawan_field_of_interest(field_of_interest_id)",
    "relawan_mastered_language(mastered_language_id)"
    // "users(fullname)"
  ];
  var params = "?select="+select.join(',')+"&status=eq.3&public_id=eq."+id;
  return await fetchGet("data/relawan"+params);
};