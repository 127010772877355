import axios from 'axios';
import client from './client';

const fetchHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json;charset=utf-8',
};

const exceptionCancelURLs = [
  'data/event'
]

const CancelToken = axios.CancelToken;
let source = {};

export const fetchGet = (url, enableCancelToken=true, forceCancelPrev=true, configHeader={}) => new Promise((resolve, reject) => {
  const baseUri = url.split(/[?#]/)[0];
  if ((!exceptionCancelURLs.includes(baseUri) || forceCancelPrev) && source[baseUri]){
    source[baseUri].cancel();
  };
  source[baseUri] = CancelToken.source();

  client.get(url, { headers: { ...fetchHeaders, ...configHeader}, cancelToken: enableCancelToken ? source[baseUri].token : null })
    .then(res => resolve(res.data))
    .catch(err => reject(err.response));
})

export const fetchPost = (url, data, forceCancelPrev=true, configHeader={}) => new Promise((resolve, reject) => {
  const baseUri = url.split(/[?#]/)[0];
  if ((!exceptionCancelURLs.includes(baseUri) || forceCancelPrev) && source[baseUri]){
    source[baseUri].cancel();
  };
  source[baseUri] = CancelToken.source();

  client({
    method: 'post',
    headers: { ...fetchHeaders, ...configHeader},
    url, data,
    cancelToken: source[baseUri].token

  })
    .then(res => resolve(res.data))
    .catch(err => reject(err.response));
})

export const fetchPut = (url, data, forceCancelPrev=true) => new Promise((resolve, reject) => {
  const baseUri = url.split(/[?#]/)[0];
  if ((!exceptionCancelURLs.includes(baseUri) || forceCancelPrev) && source[baseUri]){
    source[baseUri].cancel();
  };
  source[baseUri] = CancelToken.source();

  client({
    method: 'put',
    headers: fetchHeaders,
    url, data,
    cancelToken: source[baseUri].token
  })
    .then(res => resolve(res.data))
    .catch(err => reject(err.response));
})

export const fetchDelete = (url, forceCancelPrev=true) => new Promise((resolve, reject) => {
  const baseUri = url.split(/[?#]/)[0];
  if ((!exceptionCancelURLs.includes(baseUri) || forceCancelPrev) && source[baseUri]){
    source[baseUri].cancel();
  };
  source[baseUri] = CancelToken.source();

  client.delete(url, { headers: fetchHeaders, cancelToken: source[baseUri].token })
    .then(res => resolve(res.data))
    .catch(err => reject(err.response));
})

export const fetchPatch = (url, data, forceCancelPrev=true) => new Promise((resolve, reject) => {
  const baseUri = url.split(/[?#]/)[0];
  if ((!exceptionCancelURLs.includes(baseUri) || forceCancelPrev) && source[baseUri]){
    source[baseUri].cancel();
  };
  source[baseUri] = CancelToken.source();

  client({
    method: 'patch',
    headers: fetchHeaders,
    url, data,
    cancelToken: source[baseUri].token
  })
    .then(res => resolve(res.data))
    .catch(err => reject(err.response));
})

export const fetchPostDownload = (url, data, forceCancelPrev=true) => new Promise((resolve, reject) => {
  const baseUri = url.split(/[?#]/)[0];
  if ((!exceptionCancelURLs.includes(baseUri) || forceCancelPrev) && source[baseUri]){
    source[baseUri].cancel();
  };
  source[baseUri] = CancelToken.source();

  client({
    method: 'post',
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    responseType: 'blob',
    url, data,
    cancelToken: source[baseUri].token

  })
    .then(res => resolve(res.data))
    .catch(err => reject(err.response));
})

export const fetchPostUpload = (url, data) => new Promise((resolve, reject) => {
  const baseUri = url.split(/[?#]/)[0];
  if ((!exceptionCancelURLs.includes(baseUri)) && source[baseUri]){
    source[baseUri].cancel();
  };
  source[baseUri] = CancelToken.source();

  client({
    method: 'post',
    headers: { 'Content-Type': 'application/octet-stream' },
    url, data,
    cancelToken: source[baseUri].token

  })
    .then(res => resolve(res.data))
    .catch(err => reject(err.response));
})