import React, { useEffect, useState } from 'react';
import { setPassword, setPasswordMitra } from '../../services/auth-service';
import useQuery from '../../utils/useQuery';

function Forms() {

    const query = useQuery();
    const [formData, setFormData] = useState({
        token: '',
        password: ''
    });

    const [confirmPass, setConfirmPass] = useState('');
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [tokenIsNotExist, setTokenIsNotExist] = useState(null);

    useEffect(() => {
        if (query.get('token')) {
            setFormData({ ...formData, token: query.get('token')});
        } else {
            setTokenIsNotExist('Token is not exist!');
        }
    }, []);

    const onSubmit = () => {
        setLoading(true);
        setPasswordMitra(formData).then(resp => {
            setResponse('success');
            setLoading(false);
        }, error => {
            console.log(error);
            setResponse(error.data.error);
            setLoading(false);
            window.scrollTo(0,0);
        })
    }

    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            { response === 'success' && <div className='contact-form'>
                                <div className='alert-response'>
                                    <h4>Selamat akun Anda telah aktif!</h4>
                                    <p>Set kata sandi berhasil</p>
                                </div>
                            </div> }
                            { response && response !== 'success' && <div className='contact-form'>
                                <div className='alert-response'>
                                    <h4 className='text-danger'>Set password gagal!</h4>
                                    <p className='text-danger'>{ response }</p>
                                </div>
                            </div> }
                            { tokenIsNotExist && <div className='contact-form'>
                                <div className='alert-response'>
                                    <h4 className='text-danger'>Something wrong!</h4>
                                    <p className='text-danger'>{ tokenIsNotExist }</p>
                                </div>
                            </div> }
                            { !tokenIsNotExist && response !== 'success' && <div className="contact-form">
                                <h4>Set Password</h4>
                                <p>Silakan isi form di bawah ini untuk mengisi kata sandi Anda</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input 
                                            type="password" 
                                            name="f-name"
                                            placeholder="Kata Sandi" 
                                            onChange={(event) => setFormData({...formData, password: event.target.value})}/>
                                        <div className='error-container'>
                                            { !formData.password && <div className='text-error'>Kata sandi tidak boleh kosong</div> }
                                            { (formData.password && formData.password.length < 6) && <div className='text-error'>Kata sandi minimal 6 karakter</div> }
                                            { (formData.password && formData.password.indexOf(' ') >= 0) && <div className='text-error'>Kata sandi tidak boleh mengandung spasi</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <input 
                                            type="password" 
                                            name="f-name" 
                                            placeholder="Konfirmasi Kata Sandi" 
                                            onChange={(event) => setConfirmPass(event.target.value)}/>
                                        <div className='error-container'>
                                            {/* { !confirmPass && <div className='text-error'>Konfirmasi kata sandi tidak boleh kosong</div> } */}
                                            { (formData.password && confirmPass !== formData.password) && <div className='text-error'>Konfirmasi kata sandi harus sama dengan kata sandi</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-right">
                                        <input 
                                            type="button" 
                                            name="submit" 
                                            value={loading ? `Harap Tunggu...` : `Simpan`}
                                            disabled={
                                                !formData.password ||
                                                (formData.password && formData.password.length < 6) ||
                                                (formData.password && formData.password.indexOf(' ') >= 0) ||
                                                (formData.password && confirmPass !== formData.password) ||
                                                loading
                                            }
                                            onClick={onSubmit}
                                            />
                                    </div>
                                </div>
                            </div> }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Forms;
