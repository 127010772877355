import { Dialog, DialogContent, makeStyles, DialogTitle, IconButton, DialogActions } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
    dialog: {
        maxWidth: '80%',
        width: '80%'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})

const PopupTC = ({open, onClose, onAgree}) => {

    const classes = useStyles();
    
    useEffect(() => {
        // setLoading(true);
        if (open) {
            
        }
    }, [open]);

    return (
        <Dialog
            className={`popup`}
            fullwidth
            classes={{ paper: classes.dialog }}
            open={open}
            keepMounted
            onClose={() => onClose()}
            >
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <div>
                    <h3>{`Term & Condition`}</h3>
                    <hr/>
                </div>
                <IconButton onClick={() => onClose()}>
                    <i className="fas fa-times"/>
                </IconButton>
            </DialogTitle>
            <DialogContent id="dialog-content">
                <ol style={{listStyleType: 'decimal'}} className='tc-ol'>
                    <li>Relawan memiliki komitmen untuk bisa menjalankan tugas Kerelawanan SiapBergerak sesuai dengan kebutuhan program yang sedang dilaksanakan;</li>
                    <li>Relawan memiliki alat komunikasi dan laptop pribadi,  untuk digunakan selama bertugas sebagai relawan;</li>
                    <li>Relawan bersedia menerima insentif, yang besarannya sesuai dengan ketersediaan  oleh mitra di setiap program yang sedang dilaksanakan;</li>
                    <li>Relawan siap ditempatkan di titik gerai yang ditentukan panitia;</li>
                    <li>Relawan bersedia menandatangani kontrak kerja secara sadar dan tanpa paksaan sesuai dengan kebutuhan program;</li>
                    <li>Bagi relawan dengan program tertentu, wajib memenuhi persyaratan tertentu seperti contohnya memiliki STR;</li>
                    <li>Relawan bersedia mengikuti kewajiban yang telah ditetapkan;</li>
                    <li>Berkewajiban menjaga kerahasiaan data selama kegiatan berlangsung, untuk tidak disebarluaskan selama dan sesudah kegiatan berakhir;</li>
                    <li>Tidak akan menyebarkan ujaran kebencian, menyampaikan fitnah, selama dan sesudah kegiatan berlangsung. Jika terbukti melakukan pelanggaran,  maka akan ditindaklanjuti ke pihak berwajib dan siap menerima sanksi pidana dan/atau perdata atas pelanggaran yang dilakukan;</li>
                    <li>Menerima dan menjalankan sanksi pidana dan/atau perdata ketika melakukan pelanggaran terhadap hukum positif yang berlaku di Negara Kesatuan Republik Indonesia; </li>
                    <li>Menerima dan menjalankan sanksi atas segala tindakan, sikap, perkataan, dan aktivitas relawan yang mencoreng nama baik pribadi, mitra dan SiapBergerak di ruang luring dan daring, sesuai peraturan yang berlaku di SiapBeregrak.com dan tata aturan di Negara Kesatuan Republik Indonesia;</li>
                    <li>Memberikan informasi dan data yang sebenar-benarnya sesuai kebutuhan SiapBergerak;</li>
                    <li>Relawan siap menerapkan etika kerja dan menjaga harkat dan martabat pribadi, keluarga, dan SiapBergerak;</li>
                    <li>Tidak terlibat dalam tindakan kriminal, sebagai pengguna maupun pengedar minuman keras (miras), narkotika dan obat-obatan terlarang (narkoba); </li>
                    <li>Tidak melakukan kekerasan fisik, mental, verbal, non-verbal dan/atau seksual terhadap sesama relawan dan  masyarakat baik secara luring dan daring, serta siap menerima sanksi pidana dan/atau perdata atas pelanggaran yang dilakukan. </li>
                    <li>Relawan bersedia memenuhi kebutuhan data, dalam program SiapBergerak. Diantaranya bila terdaftar dan memiliki NPWP, BPJS.</li>
                </ol>
            </DialogContent>
            <DialogActions id="dialog-action" style={{margin: '20px 30px'}}>
                <button type='button' className='btn btn-success' onClick={() => onAgree()}>Setuju</button>
            </DialogActions>
        </Dialog>
    )
}

export default PopupTC;