import React, { useEffect } from 'react';
import logo from '../../assets/images/custom/logo.png';
import StickyMenu from '../../lib/StickyMenu';
import Navigation from '../Navigation';

function HomeOneHeader({ action }) {
    useEffect(() => {
        StickyMenu();
    }, []);
    return (
        <header className="appie-header-area appie-sticky">
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                            <div className="appie-logo-box">
                                <a href="/">
                                    <img src={logo} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-8 col-sm-7 col-6 order-5 order-sm-3">
                            <div className="appie-header-main-menu text-right">
                                <Navigation />
                            </div>
                            <div className="appie-btn-box text-right">
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomeOneHeader;
