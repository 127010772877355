import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FooterHomeOne from './FooterHomeOne';
import SiapBergerakHomeOne from './SiapBergerakHomeOne';
import HomeOneHeader from './HomeOneHeader';
import MissionHomeOne from './MissionHomeOne';
import ServicesHomeOne from './ServicesHomeOne';
import EventFavHomeOne from './EventFavHomeOne';
import EventUpdateHomeOne from './EventUpdateHomeOne';
import DownloadHomeOne from './DownloadHomeOne';

function Home() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <SiapBergerakHomeOne />
            
            <MissionHomeOne/>
            <ServicesHomeOne/>
            <EventFavHomeOne/>
            <EventUpdateHomeOne/>
            <DownloadHomeOne/>

            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Home;
