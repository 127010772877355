import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { getDistricts, getOccupations, getProvinces, getRegencies, getVillages, uploadFile } from '../../../services/master-data';
import { deleteCertificates, getDetailRelawan, saveOccupations, saveRelawan, updateRelawan } from '../../../services/relawan-service';
import useQuery from '../../../utils/useQuery';
import FormDetail from '../Registration/FormDetail';

const Forms = ({ }) =>  {

    const query = useQuery();
    const [done, setDone] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tokenValid, setTokenValid] = useState(null);

    const [oriData, setOriData] = useState();
    
    const onSuccess = () => {
        setSuccess(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [error]);

    useEffect(() => {
        fetchDetailRelawan();
    }, []);

    const fetchDetailRelawan = () => {
        setLoading(true);
        getDetailRelawan(query.get('token')).then(resp => {
            if (resp && resp.length > 0) {
                const data = resp[0];
                setTokenValid(true);
                setOriData(data);
            } else {
                setTokenValid(false);
            }
            setLoading(false);
        }, error => {
            console.log(error);
        })
    }

    // const uploadKtp = () => {
    //     setLoading(true);
    //     setDone(false);
    //     setSuccess(false);
    //     setError(false);

    //     if (!formData.ktp) {
    //         uploadFile(formData.ktp_file).then(resp => {
    //             console.log(resp);
    //             if (resp) {
    //                 onSubmit(resp);
    //             }
    //         }, error => {
    //             console.log(error);
    //             setDone(true);
    //             setLoading(false);
    //             setError(error.data?.message ?? "Unknown error");
    //         })
    //     } else {
    //         onSubmit(formData.ktp.id);
    //     }
    // }
    // const uploadCertificates = (relawanId, cleanCertificate = [], index = 0) => {
    //     const data = { ...formData.occupations[index] };
    //     data.relawan_id = relawanId;
    //     data.occupation_id = formData.occupations[index].id;
    //     delete data.id;

    //     if (data.certificate) {
    //         uploadFile(data.certificate).then(resp => {
    //             console.log(resp);
    //             if (resp) {
    //                 data.certificate_file_id = resp;
    //                 delete data.certificate;
    //                 delete data.file_public_id;
    //                 cleanCertificate.push(data);

    //                 if ((index+1) < formData.occupations.length) {
    //                     uploadCertificates(relawanId, cleanCertificate, index+1);
    //                 } else {
    //                     saveCertificates(cleanCertificate);
    //                 }
    //             }
    //         }, error => {
    //             console.log(error);
    //             setDone(true);
    //             setLoading(false);
    //             setError(error.data?.message ?? "Unknown error");
    //         })
    //     } else {
    //         delete data.certificate;
    //         delete data.file_public_id;
    //         cleanCertificate.push(data);

    //         if ((index+1) < formData.occupations.length) {
    //             uploadCertificates(relawanId, cleanCertificate, index+1);
    //         } else {
    //             saveCertificates(cleanCertificate);
    //         }
    //     }
    // }
    // const saveCertificates = (certificates) => {
    //     saveOccupations(certificates).then(resp => {
    //         console.log("certificates", resp);
    //         if (resp.length === certificates.length) {
    //             setDone(true);
    //             setLoading(false);
    //             setSuccess(true);
    //             onSuccess();
    //         }
    //     }, error => {
    //         console.log(error);
    //         setDone(true);
    //         setLoading(false);
    //         setError(error.data?.message ?? "Unknown error");
    //     })
    // }

    // const onSubmit = (ktp_file_id) => {
    //     const data = { ...oriData };
    //     data.ktp_file_id = ktp_file_id;

    //     delete data.ktp_file;
    //     delete data.ktp;
    //     delete data.occupations;
    //     delete data.province_id;
    //     delete data.regency_id;
    //     delete data.district_id;

    //     // Adjust the values
    //     data.email = data.email ? data.email.toLowerCase() : null;
    //     data.phone = data.phone ? data.phone.replace('+', '') : null;
    //     data.phone = data.phone ? (data.phone.indexOf('0') === 0 ? '62' + data.phone.substring(1, data.phone.length) : data.phone) : null;
    //     data.status = 0;

    //     updateRelawan(oriData.id, data).then(resp => {
    //         if (formData.occupations.length > 0) {
    //             deleteCertificates(oriData.id).then(resp => {
    //                 uploadCertificates(oriData.id);
    //             })
    //         } else {
    //             setDone(true);
    //             setLoading(false);
    //             setSuccess(true);
    //             onSuccess();
    //         }
    //     }, error => {
    //         console.log(error);
    //         setDone(true);
    //         setLoading(false);
    //         setError(error.data?.message ?? "Unknown error");
    //     })
    // }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Harap Tunggu...'
            >
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            { tokenValid === false && <div className="contact-form">
                                <h4 className='text-danger'>Token is not valid!</h4>
                                <p className='text-danger' style={{margin:0}}>Token tidak ada atau sudah kadaluarsa</p>
                            </div> }
                            { done && success && <div className="contact-form">
                                <h4 className='text-success'>Update data berhasil!</h4>
                                <p className='text-success' style={{margin:0}}>Data Anda akan kami verifikasi terlebih dahulu.</p>
                            </div> }
                            { done && error && <div className="contact-form">
                                <h4 className='text-danger'>Update data gagal!</h4>
                                <p className='text-danger' style={{margin:0}}>{error}</p>
                            </div> }
                            { (!done || (done && error)) && tokenValid === true && <div className="contact-form">
                                <p>
                                    Silakan revisi form di bawah ini untuk daftar sebagai relawan
                                    <hr/>
                                    { oriData && oriData.remark && <div className='text-error'>Catatan dari Admin: <br/><b style={{color: 'rgb(133, 13, 13)'}}>{ oriData.remark }</b><br/></div>}
                                </p>
                                
                                { oriData && <FormDetail 
                                    loading={loading} 
                                    onLoading={(loading) => setLoading(loading)} 
                                    onSuccess={() => setSuccess(true)}
                                    method_id={oriData.method_id}
                                    email={oriData.email}
                                    oriData={oriData}
                                    /> }
                            </div> }
                        </div>
                    </div>
                </div>
            </section>
        </LoadingOverlay>
    );
}

export default Forms;
