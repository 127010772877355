import { fetchGet } from "./fetch";

export const getLatestEvents = async () => {
  return await fetchGet("data/event?select=id,name,created_at,files!fk_event_banner(public_id)&is_active=is.true&order=created_at.desc", true, false, {
    "Range-Unit" : "items",
    "Range": `0-8`,
    "Prefer": "count=exact"
  });
};

export const getFavEvents = async () => {
  return await fetchGet("data/event?select=id,name,created_at,files!fk_event_banner(public_id)&is_active=is.true&is_favorite=is.true", true, false, {
    "Range-Unit" : "items",
    "Range": `0-4`,
    "Prefer": "count=exact"
  });
};

export const getDetailEvent = async (id) => {
  const select = [
    "*",
    "mitra(name)",
    "files!fk_event_banner(id,public_id)",
    "event_team(name, event_team_role(num_of_personnel))"
  ];
  var params = "?select="+select.join(',')+"&id=eq."+id;
  return await fetchGet("/data/event"+params);
};