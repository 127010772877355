import { fetchDelete, fetchGet, fetchPost } from "./fetch";

export const checkToken = async (token) => {
  return await fetchPost("data/rpc/check_token", { token: token }, true, {
    prefer: "params=single-object"
  });
};
export const deactivateToken = async (token) => {
  return await fetchDelete("data/registration?token=eq."+token);
};
export const resetPassword = async (formData) => {
  return await fetchPost("reset-password", formData, true, {
    prefer: "params=single-object"
  });
};
export const setPassword = async (formData) => {
  return await fetchPost("set-password", formData, true, {
    prefer: "params=single-object"
  });
};
export const setPasswordSupervisor = async (formData) => {
  return await fetchPost("set-password-supervisor", formData, true, {
    prefer: "params=single-object"
  });
};
export const setPasswordMitra = async (formData) => {
  return await fetchPost("set-password-mitra", formData, true, {
    prefer: "params=single-object"
  });
};
export const checkMemberConfirmation  = async (token) => {
  return await fetchPost("data/rpc/check_token_member_registration", { token: token }, true, {
    prefer: "params=single-object"
  });
};