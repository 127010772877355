import React from 'react';
import IconOne from '../../assets/images/custom/map-id.png';
import IconTwo from '../../assets/images/custom/users.png';
import IconThree from '../../assets/images/custom/phone-signal.png';
import IconFour from '../../assets/images/custom/service-payment.png';
import IconFive from '../../assets/images/custom/service-group.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-10 pb-40 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="section-title text-center">
                            <h2>
                                Wujudkan Program Sosialmu <br /> Bersama Relawan <span className='sb-yellow'>SIAP</span><span className='sb-blue'>BERGERAK</span>
                            </h2>
                            <p>Temukan profil relawan yang cocok dari puluhan ribu relawan yang telah bergabung</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className='img-head'>
                                <img src={IconOne} alt="" />
                            </div>
                            <p>Temukan relawan dari daerah tertentu</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className='img-head'>
                                <img src={IconTwo} alt="" />
                            </div>
                            <p>Temukan relawan dengan keahlian tertentu</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className='img-head'>
                                <img src={IconThree} alt="" />
                            </div>
                            <p>Pantau seluruh proses secara digital</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row" style={{display: 'flex', alignContent:'center', justifyContent: 'center'}}>
                        <div className="col-lg-4 col-md-4">
                            <div
                                className="appie-single-service text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className='img-head'>
                                    <img src={IconFour} alt="" />
                                </div>
                                <p>Sistem pembayaran yang terintegrasi dengan aplikasi</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div
                                className="appie-single-service text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className='img-head'>
                                    <img src={IconFive} alt="" />
                                </div>
                                <p>Sistem absensi yang mudah dan akurat</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
