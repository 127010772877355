import React, { useEffect, useState } from 'react';
import { getPaymentMethods, uploadFile } from '../../services/master-data';
import LoadingOverlay from 'react-loading-overlay';
import { checkEmailMitra, saveMitra } from '../../services/mitra-service';
import PopupTC from './PopupTC';

const Forms = () =>  {
    const [done, setDone] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tcChecked, setTcChecked] = useState(false);
    const [openTc, setOpenTc] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        npwp: '',
        phone: '',

        pic_fullname: '',
        pic_email: '',
        pic_nik: '',
        pic_npwp: '',

        program_summary: '',
        providable: [],

        insurance_available: false,
        eo_available: false,
        supervisor_available: false,

        payment_method_id: 1,
        fee: false,
        
        mou_file: null,
        program_concept_file: null,
    });

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [providables, setProvidables] = useState(["Makanan", "Kaos", "Tenda/Gerai", "Biaya Transportasi"]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [error]);

    useEffect(() => {
        fetchPaymentMethods();
    }, []);

    const fetchPaymentMethods = () => {
        getPaymentMethods().then(resp => {
            setPaymentMethods(resp);
        }, error => {
            console.log(error);
        })
    }
    
    const onSelectFileMou = (event) => {
        setFormData({...formData, mou_file: event.target.files[0]});
    }
    const onSelectFileConcept = (event) => {
        setFormData({...formData, program_concept_file: event.target.files[0]});
    }

    const checkEmail = () => {
        setLoading(true);
        setDone(false);
        setSuccess(false);
        setError(false);

        checkEmailMitra(formData.email).then(resp => {
            if (resp.length > 0) {
                setDone(true);
                setLoading(false);
                setError("Email "+resp[0].email+" sudah terdaftar. Harap ganti dengan email yang lain.");
            } else {
                // uploadMou();
                uploadProgramConcept();
            }
        }, err => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(error.data?.message ?? "Unknown error");
        });
    }
    
    const uploadMou = () => {
        setLoading(true);
        setDone(false);
        setSuccess(false);
        setError(false);

        uploadFile(formData.mou_file).then(resp => {
            console.log(resp);
            if (resp) {
                uploadProgramConcept(resp);
            }
        }, error => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(error.data?.message ?? "Unknown error");
        })
    }

    const uploadProgramConcept = (mouResp = null) => {
        setLoading(true);

        uploadFile(formData.program_concept_file).then(resp => {
            console.log(resp);
            if (resp) {
                onSubmit(mouResp, resp);
            }
        }, error => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(error.data?.message ?? "Unknown error");
        })
    }

    const onSubmit = (mou_file_id, program_concept_file_id) => {
        const data = { ...formData };
        data.mou_file_id = mou_file_id;
        data.program_concept_file_id = program_concept_file_id;
        data.providable = JSON.stringify(data.providable);

        delete data.mou_file;
        delete data.program_concept_file;

        saveMitra(data).then(resp => {
            console.log("mitra", resp);
            if (resp && resp.length > 0) {
                setDone(true);
                setLoading(false);
                setSuccess(true);
            }
        }, error => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(error.data?.message ?? "Unknown error");
        })
    }

    const isValidForm = () => {
        return formData.name && 
            formData.email && 
            formData.npwp &&
            formData.phone &&
        
            formData.pic_fullname &&
            formData.pic_email &&
            formData.pic_nik &&
            formData.pic_nik.trim().length === 16 && 

            formData.program_summary &&

            formData.program_concept_file &&

            tcChecked;
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Data sedang dikirim. Harap Tunggu.'
            >
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            { done && success && <div className="contact-form">
                                <h4 className='text-success'>Pendaftaran mitra berhasil!</h4>
                                <p className='text-success' style={{margin:0}}>Data Anda akan kami verifikasi terlebih dahulu.</p>
                            </div> }
                            { done && error && <div className="contact-form">
                                <h4 className='text-danger'>Pendaftaran mitra gagal!</h4>
                                <p className='text-danger' style={{margin:0}}>{error}</p>
                            </div> }
                            { (!done || (done && error)) && <div className="contact-form">
                                <p>Silakan isi form di bawah ini untuk daftar sebagai mitra</p>
                        
                                <h5>Identitas Instansi</h5>
                                <hr/>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className='label-sm'>Nama Instansi</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.fullname}
                                            placeholder="Nama Instansi"
                                            onChange={(event) => setFormData({...formData, name: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.name && <div className='text-error'>Nama Instansi tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className='label-sm'>Email Aktif Instansi</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            placeholder="Alamat Email Instansi"
                                            onChange={(event) => setFormData({...formData, email: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.email && <div className='text-error'>Email tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className='label-sm'>No Telepon Instansi/PJ</label>
                                        <input
                                            type="number"
                                            name="phone"
                                            value={formData.phone}
                                            placeholder="Nomor Telepon"
                                            onChange={(event) => setFormData({...formData, phone: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.phone && <div className='text-error'>Nomor telepon tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className='label-sm'>No NPWP Instansi</label>
                                        <input
                                            type="text"
                                            name="npwp"
                                            placeholder="No NPWP"
                                            value={formData.npwp}
                                            onChange={(event) => setFormData({...formData, npwp: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.npwp && <div className='text-error'>No NPWP instansi tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <label className='label-sm'>Penanggung Jawab</label>
                                        <input
                                            type="text"
                                            name="pic_fullname"
                                            placeholder="Penanggung Jawab"
                                            value={formData.pic_fullname}
                                            onChange={(event) => setFormData({...formData, pic_fullname: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.pic_fullname && <div className='text-error'>Nama penanggung jawab tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className='label-sm'>Email Penanggung Jawab</label>
                                        <input
                                            type="email"
                                            name="pic_email"
                                            value={formData.pic_email}
                                            placeholder="Alamat Email Penanggung Jawab"
                                            onChange={(event) => setFormData({...formData, pic_email: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.pic_email && <div className='text-error'>Email penanggung jawab tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className='label-sm'>NIK KTP Penanggung Jawab</label>
                                        <input
                                            type="number"
                                            name="pic_nik"
                                            length={16}
                                            maxLength={16}
                                            value={formData.pic_nik}
                                            placeholder="NIK"
                                            onChange={(event) => setFormData({...formData, pic_nik: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.pic_nik && <div className='text-error'>NIK KTP penanggung jawab tidak boleh kosong</div> }
                                            { formData.pic_nik && formData.pic_nik.trim().length != 16 && <div className='text-error'>NIK KTP penanggung jawab harus 16 digit</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className='label-sm'>No NPWP Penanggung Jawab</label>
                                        <input
                                            type="text"
                                            name="pic_npwp"
                                            placeholder="No NPWP Penanggung Jawab"
                                            value={formData.pic_npwp}
                                            onChange={(event) => setFormData({...formData, pic_npwp: event.target.value})}
                                        />
                                        <div className='error-container'>
                                            { !formData.pic_npwp && <div className='text-error'>No NPWP penanggung jawab tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                </div>

                                <br/>
                                <h5>Penjelasan Program</h5>
                                <hr/>
                                <div className='row'>
                                    <div className="col-md-12">
                                        <label className='label-sm'>Summary Program</label>
                                        <textarea
                                            name="program_summary"
                                            value={formData.program_summary}
                                            placeholder="Summary Program"
                                            onChange={(event) => setFormData({...formData, program_summary: event.target.value})}
                                        ></textarea>
                                        <div className='error-container'>
                                            { !formData.program_summary && <div className='text-error'>Summary program tidak boleh kosong</div> }
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className='upload-container' style={{marginTop: '5px'}}>
                                            <label htmlFor="upload"><b>Konsep Program</b> (file harus dalam bentuk .pdf)</label><br/>
                                            {/* { formData.program_concept_file && <><img src={URL.createObjectURL(formData.program_concept_file)} width={`200px`} alt="Loading image" style={{background:'#d0d0d0'}}/></> } */}
                                            <div className='error-container' style={{margin: '5px'}}>
                                                { !formData.program_concept_file && <div className='text-error'>File konsep program tidak boleh kosong</div> }
                                            </div>
                                            <input type={'file'} name="program_concept" accept='.pdf' onChange={onSelectFileConcept}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-20" style={{borderBottom: 'thin solid #f0f0f0'}}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label className='label'>Ketersediaan Asuransi Bagi Karyawan</label>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='radio-container'>
                                                    <input 
                                                        type='radio'
                                                        value={0} 
                                                        checked={!formData.insurance_available}
                                                        defaultChecked={!formData.insurance_available}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, insurance_available: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Tidak</div>
                                                    <input 
                                                        type='radio'
                                                        value={1} 
                                                        checked={formData.insurance_available}
                                                        defaultChecked={formData.insurance_available}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, insurance_available: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Ya</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-20" style={{borderBottom: 'thin solid #f0f0f0'}}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label className='label'>Instansi Menyediakan (boleh kosong)</label>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='row'>
                                                    { providables.map((x, i) => <div className='col-md-3'>
                                                        <input 
                                                            type='checkbox'
                                                            key={i} 
                                                            value={x} 
                                                            checked={formData.pro && formData.providable.includes(x)}
                                                            defaultChecked={formData.providable && formData.providable.includes(x)}
                                                            onChange={(event) => {
                                                                if (event.target.checked) {
                                                                    formData.providable.push(event.target.value);
                                                                } else {
                                                                    const index = formData.providable.indexOf(event.target.value);
                                                                    if (index > -1) {
                                                                        formData.providable.splice(index, 1); // 2nd parameter means remove one item only
                                                                    }
                                                                }
                                                                setFormData({  ...formData });
                                                            }}
                                                            /> 
                                                            &nbsp;{x}
                                                    </div> ) } 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-20" style={{borderBottom: 'thin solid #f0f0f0'}}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label className='label'>Apakah Memiliki EO Untuk Program?</label>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='radio-container'>
                                                    <input 
                                                        type='radio'
                                                        value={0} 
                                                        checked={!formData.eo_available}
                                                        defaultChecked={!formData.eo_available}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, eo_available: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Tidak</div>
                                                    <input 
                                                        type='radio'
                                                        value={1} 
                                                        checked={formData.eo_available}
                                                        defaultChecked={formData.eo_available}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, eo_available: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Ya</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-20" style={{borderBottom: 'thin solid #f0f0f0'}}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label className='label'>Apakah Memiliki Supervisor Untuk Setiap Titik?</label>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='radio-container'>
                                                    <input 
                                                        type='radio'
                                                        value={0} 
                                                        checked={!formData.supervisor_available}
                                                        defaultChecked={!formData.supervisor_available}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, supervisor_available: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Tidak</div>
                                                    <input 
                                                        type='radio'
                                                        value={1} 
                                                        checked={formData.supervisor_available}
                                                        defaultChecked={formData.supervisor_available}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, supervisor_available: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Ya</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-20" style={{borderBottom: 'thin solid #f0f0f0', display: 'none'}}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label className='label'>Metode Pembayaran</label>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='radio-container'>
                                                    <input 
                                                        type='radio'
                                                        value={1} 
                                                        checked={formData.payment_method_id === 1}
                                                        defaultChecked={formData.payment_method_id === 1}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, payment_method_id: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Bank</div>
                                                    <input 
                                                        type='radio'
                                                        value={2} 
                                                        checked={formData.payment_method_id === 2}
                                                        defaultChecked={formData.payment_method_id === 2}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, payment_method_id: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Gopay</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-20" style={{borderBottom: 'thin solid #f0f0f0', display: 'none'}}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label className='label'>Fee transfer antar bank (untuk bank di luar Bank Himbara). Apakah Bersedia menanggung biaya tambahan transfer?</label>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='radio-container'>
                                                    <input 
                                                        type='radio'
                                                        value={0} 
                                                        checked={!formData.fee}
                                                        defaultChecked={!formData.fee}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, fee: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Tidak</div>
                                                    <input 
                                                        type='radio'
                                                        value={1} 
                                                        checked={formData.fee}
                                                        defaultChecked={formData.fee}
                                                        onChange={(event) => {
                                                            setFormData({  ...formData, fee: +event.target.value });
                                                        }}
                                                    /> 
                                                    <div className='pl-10 pr-20'>Ya</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br/>
                                <br/>
                                {/* <h5>Surat MOU Kerjasama</h5>
                                <hr/> */}
                                <div className='row'>
                                    {/* <div className="col-md-12">
                                        <div className='upload-container'>
                                            <label htmlFor="upload"><b>Upload MOU</b> (file harus dalam bentuk .pdf)</label><br/>
                                            <div className='error-container' style={{margin: '5px'}}>
                                                { !formData.mou_file && <div className='text-error'>File MOU tidak boleh kosong</div> }
                                            </div>
                                            <input type={'file'} name="mou_file" accept='.pdf' onChange={onSelectFileMou}/>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 text-right">
                                        <div className="condition-check" style={{marginTop: '20px'}}>
                                            <input id="terms-conditions" type="checkbox" checked={tcChecked} onChange={(event) => {
                                                if (event.target.checked){
                                                    setOpenTc(true);   
                                                } else {
                                                    setTcChecked(false);
                                                }
                                            }}/>
                                            <label htmlFor="terms-conditions">
                                                Saya setuju dengan <a href="javascript:void(0)" onClick={() => setOpenTc(true)}>Terms &amp; Conditions</a>
                                            </label>
                                            <input 
                                                type="button" 
                                                name="submit" 
                                                onClick={checkEmail} 
                                                value="Daftar" 
                                                style={{marginLeft: '20px'}}
                                                disabled={!isValidForm()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> }
                        </div>
                    </div>
                </div>
                <PopupTC open={openTc} onClose={() => { setOpenTc(false); setTcChecked(false)} } onAgree={() => { setOpenTc(false); setTcChecked(true)} }></PopupTC>
            </section>
        </LoadingOverlay>
    );
}

export default Forms;
