import React from 'react';
import IconOne from '../../assets/images/custom/service-hand.png';
import IconTwo from '../../assets/images/custom/service-time.png';
import IconThree from '../../assets/images/custom/service-payment.png';
import IconFour from '../../assets/images/custom/service-group.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-10 pb-40 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-title text-center">
                            <h2>
                                Terobosan digitalisasi <br /> sistem kerelawanan.
                            </h2>
                            <p>Dengan aplikasi <span className='blue'>SiapBergerak</span>, kamu akan dicocokkan dengan aktivitas kerelawanan yang sesuai minat dan kompetensimu</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className='img-head'>
                                <img src={IconOne} alt="" />
                            </div>
                            <p>Temukan kegiatan kerelawanan sesuai dengan daerahmu</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className='img-head'>
                                <img src={IconTwo} alt="" />
                            </div>
                            <p>Proses aplikasi yang cepat dan mudah</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className='img-head'>
                                <img src={IconThree} alt="" />
                            </div>
                            <p>Sistem pembayaran yang terintegrasi dengan aplikasi</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className='img-head'>
                                <img src={IconFour} alt="" />
                            </div>
                            <p>Sistem absensi yang mudah dan akurat</p>
                        </div>
                    </div>
                </div>
                <hr/>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
