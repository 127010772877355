import { fetchGet, fetchPost, fetchPostUpload } from "./fetch";

export const getOccupations = async () => {
  return await fetchGet("data/occupation?select=id,name&is_active=is.true");
};
export const getEducations = async () => {
  return await fetchGet("data/education?select=id,name&is_active=is.true");
};
export const getFieldOfInterest = async () => {
  return await fetchGet("data/field_of_interest?select=id,name&is_active=is.true");
};
export const getMasteredLanguages = async () => {
  return await fetchGet("data/mastered_language?select=id,name&is_active=is.true");
};
export const getBanks = async () => {
  return await fetchGet("data/bank?select=id,name&is_active=is.true");
};
export const getProvinces = async () => {
  return await fetchGet("data/area_province?select=code,name");
};
export const getRegencies = async (provinceCode) => {
  return await fetchGet("data/area_regency?select=code,name,latitude,longitude&province_code=eq."+provinceCode);
};
export const getDistricts = async (regencyCode) => {
  return await fetchGet("data/area_district?select=code,name&regency_code=eq."+regencyCode);
};
export const getVillages = async (districtCode) => {
  return await fetchGet("data/area_village?select=code,name&district_code=eq."+districtCode);
};
export const uploadFile = async (file) => {
  return await fetchPostUpload("data/rpc/upload", file);
};
export const getPaymentMethods = async () => {
  return await fetchGet("data/payment_method?select=id,name&is_active=is.true");
};