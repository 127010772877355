import axios from "axios";
// const { dispatch } = reduxStore;

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const handleBaseUrlSource = (header, url) => {
  // IF the API path was in whitelist use base url api without souce
  return `${process.env.REACT_APP_BASE_URL_API}`;
  // return `http://localhost:5002/api`;
};

instance.interceptors.request.use(
  (config) => {
    if (localStorage.accessToken && localStorage.accessToken !== "undefined") {
      config.headers["Authorization"] = `Bearer ${localStorage.accessToken}`;
    }
    // Setting source of baseUrl
    config.baseURL = handleBaseUrlSource(config?.headers, config?.url);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response.headers["x-new-token"]) {
      localStorage.setItem("accessToken", response.headers["x-new-token"]);
    }
    return response;
  },
  function (error) {
    const errorData = error?.response?.data;
    const originalRequest = error.config;
    // console.log(errorData);
    console.log(errorData);
    if (
      errorData?.error?.statusCode === 401 &&
      errorData?.error?.message === "ForceLogout"
    ) {
      //do logout because inactivity
      // dispatch(setForceLoggedOutInactive(true));
    } else if (
      errorData?.error?.statusCode === 401 &&
      errorData?.error?.message === "RefreshTokenExpired"
    ) {
      //do logout because refresh token expire
      // dispatch(setForceLoggedOutTokenExpire(true));
    } else if (
      errorData?.error?.statusCode === 401 &&
      errorData?.error?.message === "TokenExpired" &&
      !originalRequest._retry
    ) {
      //do refresh token and retry the request after got the new access token
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return new Promise((resolve, reject) => {
              axios(originalRequest).then(resolve, (error) => {
                const errorData = error?.response?.data;
                // console.log('Failed second request', errorData);
                if (errorData?.error?.statusCode === 401) {
                  // dispatch(setForceLoggedOutInactive(true));
                }
                reject(error);
              });
            });
          })
          .catch((err) => {
            const errorData = err?.data;

            if (errorData?.error?.statusCode === 401) {
              // dispatch(setForceLoggedOutTokenExpire(true));
            }
            return Promise.reject(err);
          });
      } else {
        isRefreshing = true;
        // return doRefreshToken({ refreshToken: localStorage.refreshToken }).then(
        //   (response) => {
        //     isRefreshing = false;
        //     localStorage.setItem("accessToken", response.newAccessToken);
        //     originalRequest.headers["Authorization"] =
        //       "Bearer " + response.newAccessToken;
        //     processQueue(null, response.newAccessToken);
        //     return new Promise((resolve, reject) => {
        //       axios(originalRequest).then(resolve, (error) => {
        //         const errorData = error?.response?.data;
        //         // console.log('Failed second request', errorData);
        //         if (errorData?.error?.statusCode === 401) {
        //           // dispatch(setForceLoggedOutInactive(true));
        //         }
        //         reject(error);
        //       });
        //     });
        //   },
        //   (error) => {
        //     isRefreshing = false;
        //     processQueue(error, null);
        //     const errorData = error?.data;

        //     if (errorData?.error?.statusCode === 401) {
        //       // dispatch(setForceLoggedOutTokenExpire(true));
        //     }
        //     return Promise.reject(error);
        //   }
        // );
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
