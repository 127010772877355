import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../Home/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import HeaderNews from '../Event/HeaderNews';
import HeroNews from '../Event/HeroNews';
import Content from './Content';

function PrivacyPolicy() {
    const [drawer, drawerAction] = useToggle(false);
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews drawer={drawer} action={drawerAction.toggle} />
            <HeroNews
                title="Privacy Policy"
                breadcrumb={[
                    { link: '/', title: 'Beranda' },
                    { link: '/privacy-policy', title: 'Privacy Policy' },
                ]}
            />
            <Content />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default PrivacyPolicy;
