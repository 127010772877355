import React, { useEffect, useState } from 'react';
import useToggle from '../../Hooks/useToggle';
import { getDetailEvent } from '../../services/event-service';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../Home/FooterHomeOne';
import Drawer from '../Mobile/Drawer';
import Blog from './Blog';
import BlogSideBar from './BlogSideBar';
import HeaderNews from './HeaderNews';
import HeroNews from './HeroNews';
import { useParams } from 'react-router-dom';

function SingleEvent() {
    const [drawer, drawerAction] = useToggle(false);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        getDetailEvent(params.id).then((resp) => {
            console.log(resp);
            setData(resp[0]);
            setLoading(false);
        }, error => {
            console.log(error);
            setLoading(false);
        })
    }
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews action={drawerAction.toggle} />
            <HeroNews
                title="Event"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/event/detail', title: 'Detail Event' },
                ]}
            />
            <section className="blogpage-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            { data && <Blog data={data}/> }
                        </div>
                        <div className="col-lg-12">
                            { data && <BlogSideBar data={data} /> }
                        </div>
                    </div>
                </div>
            </section>
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default SingleEvent;
