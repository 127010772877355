import { Dialog, DialogContent, makeStyles, DialogTitle, IconButton, DialogActions } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LocationPicker from 'react-location-picker';

const useStyles = makeStyles({
    dialog: {
        maxWidth: '80%',
        width: '80%'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})


/* Default position */
const defaultPositionJakarta = {
    lat: -6.229728,
    lng: 106.6894312
};

const PopupLocationPicker = ({title, defaultPosition, open, onClose, onFinish}) => {

    const classes = useStyles();
    const [formData, setFormData] = useState({
        address: '',
        position: defaultPosition ? defaultPosition: defaultPositionJakarta
    })

    useEffect(() => {
        // setLoading(true);
        if (open) {
            
        }
    }, [open]);

    const handleLocationChange = ({ position, address, places }) => {
        // Set new location
        setFormData({
            address: address,
            position: position
        })
    }

    return (
        <Dialog
            className={`popup`}
            fullwidth
            classes={{ paper: classes.dialog }}
            open={open}
            keepMounted
            onClose={() => onClose()}
            >
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <div>
                    <h3>{title ? title : `Location Picker`}</h3>
                    <p>Silakan geser icon balon/pin sesuai dengan lokasi {title ? title : 'alamat domisili'} Anda</p>
                    <hr/>
                </div>
                <IconButton onClick={() => onClose()}>
                    <i className="fas fa-times"/>
                </IconButton>
            </DialogTitle>
            <DialogContent id="dialog-content">
            <div>
                <LocationPicker
                    containerElement={ <div style={ {height: '400px', width: '100%', borderRadius:'10px'} } /> }
                    mapElement={ <div style={ {height: '400px', width: '100%', borderRadius:'10px'} } /> }
                    defaultPosition={formData.position}
                    onChange={handleLocationChange}
                />
                <p>{formData.address}</p>
            </div>
            </DialogContent>
            <DialogActions id="dialog-action" style={{margin: '20px 30px'}}>
                <button type='button' className='btn btn-success' onClick={() => onFinish(formData.position)}>Selesai</button>
            </DialogActions>
        </Dialog>
    )
}

export default PopupLocationPicker;