import React, { useEffect, useState } from 'react';
import { checkMemberConfirmation, checkToken, setPassword } from '../../services/auth-service';
import useQuery from '../../utils/useQuery';

function Forms() {

    const query = useQuery();
    const [formData, setFormData] = useState({
        token: '',
        password: ''
    });

    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [tokenIsNotExist, setTokenIsNotExist] = useState(null);

    useEffect(() => {
        if (query.get('token')) {
            setLoading(true);
            checkMemberConfirmation(query.get('token')).then(resp => {
                if (resp && resp.length > 0) {
                    setResponse('success');
                } else {
                    setResponse('expired');
                }
                setLoading(false);
            }, error => {
                console.log(error);
                setResponse(error.data.error);
                setLoading(false);
                window.scrollTo(0,0);
            })
        } else {
            setTokenIsNotExist('Token is not exist!');
        }
    }, []);

    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            { response === 'success' && <div className='contact-form'>
                                <div className='alert-response'>
                                    <h4>Pendaftaran Anda Terverifikasi!</h4>
                                    <p>Data Anda sudah berada dalam antrian persetujuan. Mohon bersedia untuk menunggu persetujuan. Terima kasih.</p>
                                </div>
                            </div> }
                            { response && response !== 'success' && <div className='contact-form'>
                                <div className='alert-response'>
                                    <h4 className='text-danger'>Link Telah Kadaluarsa</h4>
                                    <p className='text-danger'>Silakan melakukan pendaftaran ulang.</p>
                                </div>
                            </div> }
                            { tokenIsNotExist && <div className='contact-form'>
                                <div className='alert-response'>
                                    <h4 className='text-danger'>Something wrong!</h4>
                                    <p className='text-danger'>{ tokenIsNotExist }</p>
                                </div>
                            </div> }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Forms;
