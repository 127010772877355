import React from 'react';
import teamImg from '../../assets/images/team.jpg'

const BlogSideBar = ({ data }) => {
    return (
        <div className="blog-sidebar" style={{marginLeft: 0}}>
            <aside className="widget widget-trend-post">
                <h3 className="widget-title">Tim</h3>
                <div className='row'>
                { data.event_team.map((x, i) => {
                    const totalPersonnel = x.event_team_role.reduce((partialSum, a) => partialSum + a.num_of_personnel, 0);
                    return <div className='col-md-4'><div className="popular-post" key={i}>
                        <img src={teamImg} alt="" />
                        <h5 style={{fontSize: '18px', paddingTop: '3px'}}>{x.name}</h5>
                        <span style={{fontSize: '14px'}}>{x.event_team_role.length} role tersedia</span>
                        <span style={{fontSize: '14px', paddingTop: '10px'}}>{totalPersonnel} relawan dibutuhkan</span>
                    </div></div>
                })}
                </div>
            </aside>
        </div>
    );
}

export default BlogSideBar;
