import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/custom/logo.png';
import iconWa from '../../assets/images/custom/wa.svg';

function DrawerMitra({ drawer, action }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('');
    const handler = (e, value) => {
        e.preventDefault();
        const getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            setSize(`${43 * getItems}px`);
            setItem(value);
        }
    };
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li
                                            onClick={(e) => handler(e, '')}
                                            id="beranda"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/">Beranda</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'daftar-mitra')}
                                            id="daftar-mitra"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/mitra">Mitra</Link>
                                        </li>
                                        {/* <li
                                            onClick={(e) => handler(e, 'daftar-relawan')}
                                            id="daftar-relawan"
                                            className="menu-item-has-children active"
                                        >
                                            <Link to="/daftar-relawan">Daftar sebagai Relawan</Link>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="text-center mt-20">
                                    <div>Follow kami di</div>
                                    <ul className="text-center">
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-instagram"></i> SiapBergerak.id
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info text-left">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-envelope"></i>{' '}
                                                admin@siapbergerak.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-phone"></i> +(21) 342 762 44
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i><img src={iconWa}/></i> +62 812-1155-7575
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-map-marker-alt"></i> Jalan Mataram No. 4, Kel. Selong, Kec. Kebayoran Baru, Kota Adm. Jakarta Selatan, Provinsi DKI Jakarta
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DrawerMitra;
