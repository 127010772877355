import React, { useEffect, useState } from 'react';
import { isValidEmail } from '../../../utils/helper';
import LoadingOverlay from 'react-loading-overlay';
import FormDetail from './FormDetail';

import { useGoogleLogin } from '@react-oauth/google';
import { fetchGet } from '../../../services/fetch';
import AppleLogin from 'react-apple-login'
import axios from 'axios';

import jwt from 'jwt-decode';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const googleClientId = '';

const Forms = () =>  {
    const [methodId, setMethodId] = useState(0);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showFormDetail, setShowFormDetail] = useState(false);

    const [googleResponse, setGoogleResponse] = useState(false);
    const [appleResponse, setAppleResponse] = useState(false);
    const [appleResponseRaw, setAppleResponseRaw] = useState({});

    const [loadingAppleData, setLoadingAppleData] = useState(false);
    const location = useLocation();
    const history = useHistory();
    
    const [formData, setFormData] = useState({
        email: '',
        phone: ''
    });

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            console.log(codeResponse)
            fetchGet("https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token="+codeResponse.access_token).then(profile => {
                console.log('profile', profile);
                onSuccessGoogle({ 
                    googleId: profile.id,
                    profileObj: {
                        email: profile.email,
                        givenName: profile.given_name,
                        familyName: profile.family_name,
                        picture: profile.picture,
                        verifiedEmail: profile.verified_email,
                        name: profile.name
                    }
                });
            }, error => {
                console.log(error);
                onFailureGoogle(error);
            })
        },
    });

    useEffect(() => {
        setShowFormDetail(false);
        if (methodId !== 1) {
            setGoogleResponse(null);
            setAppleResponse(null);
            if (methodId === 2) {

            } else if (methodId === 3) {
                
            }
        }
    }, [methodId]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get("code")) {
            processAppleResponse({
                authorization: {
                    code: query.get("code")
                }
            });
        }
    }, []);

    const onSubmit = () => {
        setShowFormDetail(true);

        // const data = { ...formData };
        // if (methodId === 1 && googleResponse) {
        //     data.email = googleResponse.profileObj.email;
        //     data.google_name = (`${googleResponse.profileObj.givenName} ${googleResponse.profileObj.familyName}`).trim();
        //     data.google_id = googleResponse.googleId;
        //     delete data.phone;
        // } else if (methodId === 2 && data.email) {
        //     delete data.phone;
        // } else if (methodId === 3 && data.phone) {
        //     delete data.email;
        // } else {
        //     setResponse("Silakan pilih metode registrasi terlebih dahulu");
        //     window.scrollTo(0,0);
        //     return;
        // }
        // data.method_id = methodId;

        // setLoading(true);
        // registerRelawan(data).then(resp => {
        //     setResponse('success');
        //     setLoading(false);
        // }, error => {
        //     console.log(error);
        //     setResponse(error.data.message);
        //     setLoading(false);
        //     window.scrollTo(0,0);
        // })
    }

    const onSuccessGoogle = (response) => {
        console.log(response);
        setGoogleResponse(response);
    }

    const onFailureGoogle = (response) => {
        console.log(response);
        setGoogleResponse(null);
        setMethodId(0);
    }
    
    const onLogoutGoogle = (response) => {
        console.log(response);
        setGoogleResponse(null);
        setMethodId(0);
    }

    const processAppleResponse = response => {
        setAppleResponseRaw(response);
        console.log(response);
        if (!response.error) {
            if (response.authorization.id_token) {
                const user = jwt(response.authorization.id_token);
                if (user.email) {
                    setAppleResponse(user)
                    setMethodId(4);
                    return;
                }
            }

            setLoadingAppleData(true);
            setMethodId(4);
            axios
                .post(process.env.REACT_APP_BASE_URL_API + "apple/auth", response)
                .then(res => {
                    setTimeout(() => {
                        console.log(res);
                        setAppleResponse(res.data)
                        setMethodId(4);
                        setLoadingAppleData(false);
                    }, 2000);
                })
                .catch(err => {
                    // console.log(err)
                    setLoadingAppleData(false);
                    alert("Loading Apple data failed. Please try again later.");

                    const queryParams = new URLSearchParams(location.search)
                    if (queryParams.has('code')) {
                        queryParams.delete('code')
                        history.replace({
                          search: queryParams.toString(),
                        })
                      }
                });
        } else {
            setAppleResponse(null);
            setMethodId(0);
        }
    };

    return (
        <LoadingOverlay
            active={loading || loadingAppleData}
            spinner
            text={ loadingAppleData ? 'Loading Apple Data. Harap Tunggu.' : 'Harap Tunggu.' }
            >
            <section className="contact-section">
                {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            { response === 'success' && <div className='contact-form'>
                                <div className='alert-response'>
                                    <h4>Link registrasi telah dikirim!</h4>
                                    <p>Silakan periksa { methodId === 1 || methodId === 2 ? 'email' : 'nomor telepon' } Anda untuk mendapatkan tautan registrasi</p>
                                </div>
                            </div> }
                            { response && response !== 'success' && <div className='contact-form'>
                                <div className='alert-response'>
                                    <h4 className='text-danger'>Registrasi gagal!</h4>
                                    <p className='text-danger'>{ response }</p>
                                </div>
                            </div> }
                            { success && <div className="contact-form">
                                <h4 className='text-success'>Pendaftaran berhasil!</h4>
                                <p className='text-success' style={{margin:0}}>Periksa email Anda untuk verifikasi dan set password.</p>
                            </div>}
                            { response !== 'success' && !success && <div className="contact-form">
                                <h4>Ayo daftar!</h4>
                                <p>Silakan pilih metode verifikasi terlebih dahulu</p>
                                <div className='row'>
                                    <div className="col-md-12">
                                        {/* <select
                                            name="methodId"
                                            placeholder="Metode verifikasi"
                                            onChange={onSelectMethod}
                                        >
                                            <option value={0}>-- Pilih Metode Verifikasi --</option>
                                            <option value={1}>Gmail</option>
                                            <option value={2}>Email</option>
                                            <option value={3}>Tidak memiliki Gmail atau Email</option>
                                        </select> */}
                                        <div className='row button-option' style={{ margin: '0 -5px'}}>
                                            {/* <div className='col-md-3'>
                                            <button 
                                                className='btn btn-primary' 
                                                onClick={(e) => {
                                                    setMethodId(1);
                                                    login();
                                                }} >
                                                    <i className='fab fa-google'></i>&nbsp;&nbsp;&nbsp;&nbsp;Gmail
                                            </button>
                                            </div>
                                            <div className='col-md-3'>
                                                <AppleLogin
                                                    clientId="com.siapbergerak.service"
                                                    redirectURI="https://siapbergerak.com"
                                                    // usePopup={true}
                                                    // callback={processAppleResponse} // Catch the response
                                                    scope="email"
                                                    responseMode="query"
                                                    render={renderProps => (  //Custom Apple Sign in Button
                                                            <button 
                                                                className='btn btn-primary' 
                                                                onClick={(e) => {
                                                                    setMethodId(4);
                                                                    renderProps.onClick();
                                                                }} >
                                                                    <i className='fab fa-apple'></i>&nbsp;&nbsp;&nbsp;&nbsp;Apple ID
                                                            </button>
                                                    )}
                                                />
                                            </div> */}
                                            <div className='col-md-6'>
                                                <button className='btn btn-primary' onClick={() => setMethodId(2)}><i className='fas fa-envelope'></i>&nbsp;&nbsp;&nbsp;&nbsp;Email</button>
                                            </div>
                                            <div className='col-md-6'>
                                                <button className='btn btn-link' onClick={() => setMethodId(3)}>Tidak memiliki Email</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop: "20px"}}>
                                    { (methodId === 1 && googleResponse !== null) && <><div className="col-md-6">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Nama"
                                            value={`${googleResponse.profileObj.givenName} ${googleResponse.profileObj.familyName}`}
                                            readOnly={true}
                                        />
                                    </div><div className="col-md-6">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Alamat Email"
                                            value={googleResponse.profileObj.email}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className='col-md-12' style={{marginBottom: '20px'}}>
                                        {/* <GoogleLogout
                                            clientId={googleClientId}
                                            buttonText="Batalkan Registrasi dengan Google"
                                            onLogoutSuccess={onLogoutGoogle}
                                            onFailure={(err) => console.log(err)} 
                                        >
                                        </GoogleLogout> */}
                                    </div>
                                    { showFormDetail && <div className='col-md-12'>
                                        <FormDetail 
                                            loading={loading} 
                                            onLoading={(loading) => setLoading(loading)} 
                                            onSuccess={() => setSuccess(true)}
                                            method_id={methodId}
                                            google_name={`${googleResponse.profileObj.givenName} ${googleResponse.profileObj.familyName}`}
                                            google_id={googleResponse.googleId}
                                            email={googleResponse.profileObj.email}
                                            />
                                    </div> }</> }
                                    { loadingAppleData && <i>Loading Apple Data</i> }
                                    { (methodId === 4 && appleResponse !== null) && <>{ appleResponse.name && <div className="col-md-6">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Nama"
                                            value={`${appleResponse.name ? appleResponse.name : ""  }`}
                                            readOnly={true}
                                        />
                                    </div> }
                                    <div className="col-md-6">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Alamat Email"
                                            value={`${appleResponse.email}`}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className='col-md-12' style={{marginBottom: '20px'}}>
                                        {/* <GoogleLogout
                                            clientId={googleClientId}
                                            buttonText="Batalkan Registrasi dengan Google"
                                            onLogoutSuccess={onLogoutGoogle}
                                            onFailure={(err) => console.log(err)} 
                                        >
                                        </GoogleLogout> */}
                                    </div>
                                    { showFormDetail && <div className='col-md-12'>
                                        <FormDetail 
                                            loading={loading} 
                                            onLoading={(loading) => setLoading(loading)} 
                                            onSuccess={() => setSuccess(true)}
                                            method_id={methodId}
                                            google_id={appleResponse.id ?? appleResponse.sub}
                                            google_name={`${appleResponse.name ? appleResponse.name : ""}`}
                                            email={appleResponse.email}
                                            />
                                    </div> }</> }
                                    { (methodId === 2) && <><div className="col-md-12">
                                        <label>Silakan masukkan email Anda</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Alamat Email"
                                            onChange={(event) => setFormData({...formData, email: event.target.value})}
                                            autoFocus
                                        />
                                        <div className='error-container'>
                                            { (methodId === 2) && !formData.email && <div className='text-error'>Email tidak boleh kosong</div> }
                                            { (methodId === 2) && formData.email && !isValidEmail(formData.email) && <div className='text-error'>Email tidak valid</div> }
                                        </div>
                                    </div>
                                    { showFormDetail && <div className="col-md-12">
                                        <FormDetail 
                                            loading={loading} 
                                            onLoading={(loading) => setLoading(loading)} 
                                            onSuccess={() => setSuccess(true)}
                                            method_id={methodId}
                                            email={formData.email}
                                            />
                                    </div> }
                                    </> }
                                    { methodId === 3 && <><div className="col-md-12">
                                        <label>Silakan masukkan nomor telepon <b>WhatsApp</b> Anda</label>
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Nomor Telepon WhatsApp (contoh: 081312341234)"
                                            onChange={(event) => setFormData({...formData, phone: event.target.value})}
                                            autoFocus
                                        />
                                        <div className='error-container'>
                                            { (methodId === 3) && !formData.phone && <div className='text-error'>Nomor telepon <b>WhatsApp</b> tidak boleh kosong</div> }
                                            { (methodId === 3) && formData.phone && formData.phone.length < 10 && <div className='text-error'>Nomor telepon <b>WhatsApp</b> minimal 10 digit</div> }
                                            { (methodId === 3) && formData.phone && (formData.phone.indexOf('0') !== 0 && formData.phone.indexOf('62') !== 0) && <div className='text-error'>Nomor telepon <b>WhatsApp</b> harus diawali dengan 0 atau 62</div> }
                                        </div>
                                    </div>
                                    { showFormDetail && <div className="col-md-12">
                                        <FormDetail 
                                            loading={loading} 
                                            onLoading={(loading) => setLoading(loading)} 
                                            onSuccess={() => setSuccess(true)}
                                            method_id={methodId}
                                            phone={formData.phone}
                                            />
                                    </div> }
                                    </> }
                                </div>
                                { methodId !== 0 && !showFormDetail && <>
                                    <hr/>
                                    <div className="col-md-12 text-right">
                                        <div className="condition-check">
                                            <input type="button" name="submit" disabled={!(
                                                (methodId === 1) && googleResponse !== null ||
                                                (methodId === 4) && appleResponse !== null ||
                                                (methodId === 2) && formData.email && isValidEmail(formData.email) ||
                                                (methodId === 3) && formData.phone && formData.phone.length >= 10 ||
                                                (methodId === 3) && formData.phone && (formData.phone.indexOf('0') === 0 || formData.phone.indexOf('62') === 0)
                                            )} value="Selanjutnya" onClick={onSubmit} style={{marginLeft: '20px'}}/>
                                        </div>
                                    </div>
                                </> }

                                {/* { appleResponseRaw && <div className="row" style={{marginTop: "20px", fontSize: "10px"}}>
                                    Note for developer:<br/>
                                    {methodId} - {JSON.stringify(appleResponse)}<br/>
                                    {JSON.stringify(appleResponseRaw)}
                                </div> } */}
                            </div> }
                        </div>
                    </div>
                </div>
                {/* </GoogleOAuthProvider> */}
            </section>
        </LoadingOverlay>
    );
}

export default Forms;