import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import Home from './components/Home';
import HomeMitra from './components/HomeMitra';
import MemberConfirmation from './components/MemberConfirmation';
import SetPassword from './components/SetPassword';
import SetPasswordMitra from './components/SetPasswordMitra';
import SignUpMitra from './components/SignUpMitra';
import SignUpRelawan from './components/SignUpRelawan/Form';
import RegistrationRelawan from './components/SignUpRelawan/Registration';
import RevisionRelawan from './components/SignUpRelawan/Revision';
import SingleEvent from './components/Event/SingleEvent';
import SetPasswordSupervisor from './components/SetPasswordSupervisor';
import ResetPassword from './components/ResetPassword';
import PrivacyPolicy from './components/PrivacyPolicy';

function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });
    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                <Router>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/daftar-relawan" component={RegistrationRelawan} />
                            <Route exact path="/daftar-relawan/:token/form" component={SignUpRelawan} />
                            <Route exact path="/edit-relawan" component={RevisionRelawan} />
                            <Route exact path="/daftar-mitra" component={SignUpMitra} />
                            <Route exact path="/reset-password" component={ResetPassword} />
                            <Route exact path="/set-password" component={SetPassword} />
                            <Route exact path="/set-password-mitra" component={SetPasswordMitra} />
                            <Route exact path="/set-password-supervisor" component={SetPasswordSupervisor} />
                            <Route exact path="/member-confirmation" component={MemberConfirmation} />
                            <Route exact path="/event/detail/:id" component={SingleEvent} />
                            <Route exact path="/" component={Home} />
                            <Route exact path="/mitra" component={HomeMitra} />
                            <Route exact path="/error" component={Error} />
                            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                            <Route component={Error} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        </>
    );
}

export default Routes;
