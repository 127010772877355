import React, { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import { getFavEvents } from '../../services/event-service';
import moment from 'moment';

const EventFavHomeOne = () => {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickPrev();
    };
    const sliderPrev = () => {
        sliderRef.current.slickNext();
    };

    const [favEvents, setFavEvents] = useState([]);
    const [loadingFavEvent, setLoadingFavEvent] = useState(false);
    const [settings, setSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    });

    useEffect(() => {
        fetchFavEvent();
    }, []);

    const fetchFavEvent = () => {
        setLoadingFavEvent(true);
        getFavEvents().then(resp => {
            console.log('favEvents', resp);
            setFavEvents(resp);
            setLoadingFavEvent(false);

            if (resp.length < 3) {
                setSettings({ ...settings, slidesToShow: resp.length});
            }
        }, error => {
            console.log(error);
            setLoadingFavEvent(false);
        })
    }

    return (
        <>
            <section className="appie-testimonial-area pt-0 pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2>Event Pilihan</h2>
                                {/* <p>Different layouts and styles for team sections.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="appie-testimonial-slider" style={{ position: 'relative' }}>
                                <span
                                    className="prev slick-arrow"
                                    style={{ display: 'block' }}
                                    onClick={sliderNext}
                                    role="button"
                                    tabIndex="0"
                                >
                                    <i className="fal fa-arrow-left" />
                                </span>
                                <Slider ref={sliderRef} dots arrows={false} {...settings}>
                                    { !loadingFavEvent && favEvents.map((x, i) => <div
                                            key={i}
                                            className="appie-blog-item mt-30 mb-30 wow animated fadeInUp"
                                            data-wow-duration="3000ms"
                                            data-wow-delay="200ms"
                                        >
                                            <div className="thumb">
                                                { x.files && <img src={`${process.env.REACT_APP_BASE_URL_API}image?id=${x.files.public_id}`} height={`350px`} alt="Loading image" style={{background:'#d0d0d0'}}/> }
                                                { !x.files && <img height={`200px`} alt="No image" style={{background:'#d0d0d0'}}/> }
                                            </div>
                                            <div className="content">
                                                <div className="blog-meta">
                                                    <ul>
                                                        <li>{moment(x.created_at).format('DD MMMM YYYY')}</li>
                                                    </ul>
                                                </div>
                                                <h3 className="title">
                                                    <a href={`/event/detail/${x.id}`} style={{color: '#0c3663'}}>
                                                        {x.name}
                                                    </a>
                                                </h3>
                                            </div>
                                        </div>
                                    )}
                                </Slider>
                                <span
                                    onClick={sliderPrev}
                                    role="button"
                                    tabIndex="-1"
                                    className="next slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-right" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </section>
        </>
    );
}

export default EventFavHomeOne;
