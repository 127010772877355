import React, { useEffect, useState } from 'react';
import { getBanks, getDistricts, getEducations, getFieldOfInterest, getMasteredLanguages, getOccupations, getProvinces, getRegencies, getVillages, uploadFile } from '../../../services/master-data';
import { saveOccupations, saveRelawan } from '../../../services/relawan-service';
import PopupLocationPicker from '../PopupLocationPicker';
import PopupTC from '../PopupTC';

const FormDetail = ({ onLoading, onSuccess, method_id, google_id, google_name, email, phone, oriData }) =>  {

    const defaultData = {
        method_id: method_id,
        google_id: google_id,
    
        fullname: google_name,
        birthplace: '',
        birthdate: '2000-01-01',
        email: email,
        phone: phone,
        npwp: '',
        institution: '',
        
        bank_account: '',
        bank_name: '',
    
        occupations: [{id: 0, certificate: null}],
    
        address: '',
        
        education: '',
        education_subject: '',
    
        expertise: '',
        experience: '',
        
        fieldOfInterests: [],
        masteredLanguages: [],
    
        short_profile: '',
    
        sosmed_ig: '',
        sosmed_twitter: '',
        sosmed_tiktok: '',
        sosmed_fb: '',
    
        ktp_file: null,
        insurance_file: null,
        member_card_file: null,
    };

    const [done, setDone] = useState(false);
    const [doneSetBirthdate, setDoneSetBirthdate] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tcChecked, setTcChecked] = useState(false);
    const [openTc, setOpenTc] = useState(false);
    const [openLocationPicker, setOpenLocationPicker] = useState(false);

    const [formData, setFormData] = useState(oriData ? { ...oriData,
        province_id: oriData.area_village.area_district.area_regency.area_province.code,
        regency_id: oriData.area_village.area_district.area_regency.code,
        district_id: oriData.area_village.area_district.code,
        village_id: oriData.area_village.code,
        occupations: oriData.relawan_occupation.map(x =>
            ({ id: x.occupation.id, certificate: null, certificate_file_id: x.files.id, file_public_id: x.files.public_id })
        ),
        fieldOfInterests: oriData.relawan_field_of_interest.map(x =>
            x.field_of_interest_id
        ),
        masteredLanguages: oriData.relawan_mastered_language.map(x =>
            x.mastered_language_id
        )
    } : defaultData);

    const [occupations, setOccupations] = useState([]);
    const [educations, setEducations] = useState([]);
    const [fieldOfInterests, setFieldOfInterests] = useState([]);
    const [masteredLanguages, setMasteredLanguages] = useState([]);
    const [banks, setBanks] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [regencies, setRegencies] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [villages, setVillages] = useState([]);

    useEffect(() => {
        onLoading(loading);
    }, [loading]);

    useEffect(() => {
        window.scrollTo(0, 600);
    }, [error, loading]);

    useEffect(() => {
        // fetchOccupations();
        // fetchProvinces();
        // fetchEducations();
        // fetchFieldOfInterests();
        // fetchMasteredLanguages();
        // fetchBanks();
    }, []);

    // useEffect(() => {
    //     if (formData.province_id !== 0) {
    //         setRegencies([]);
    //         formData.regency_id = oriData && formData.province_id === oriData.area_village.area_district.area_regency.area_province.code ? oriData.area_village.area_district.area_regency.code : 0;

    //         setDistricts([]);
    //         formData.district_id = oriData && formData.province_id === oriData.area_village.area_district.area_regency.area_province.code ? oriData.area_village.area_district.code : 0;

    //         setVillages([]);
    //         formData.village_id = oriData && formData.province_id === oriData.area_village.area_district.area_regency.area_province.code ? oriData.area_village.code : 0;

    //         fetchRegencies(formData.province_id);
    //     }
    // }, [formData.province_id]);

    // useEffect(() => {
    //     if (formData.regency_id !== 0) {
    //         setDistricts([]);
    //         formData.district_id = oriData && formData.regency_id === oriData.area_village.area_district.area_regency.code ? oriData.area_village.area_district.code : 0;

    //         setVillages([]);
    //         formData.village_id = oriData && formData.regency_id === oriData.area_village.area_district.area_regency.code ? oriData.area_village.code : 0;

    //         fetchDistricts(formData.regency_id);

    //         const regency = regencies.find(x => x.code === formData.regency_id);
    //         if (regency) {
    //             formData.address_lat = formData.address_lat ? formData.address_lat : regency.latitude;
    //             formData.address_lon = formData.address_lon ? formData.address_lon : regency.longitude;
    //         }
    //     }
    // }, [formData.regency_id]);

    // useEffect(() => {
    //     if (formData.district_id !== 0) {
    //         setVillages([]);
    //         formData.village_id = oriData && formData.district_id === oriData.area_village.area_district.code ? oriData.area_village.code : 0;

    //         fetchVillages(formData.district_id);
    //     }
    // }, [formData.district_id]);

    const fetchOccupations = () => {
        getOccupations().then(resp => {
            setOccupations(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchEducations = () => {
        getEducations().then(resp => {
            setEducations(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchFieldOfInterests = () => {
        getFieldOfInterest().then(resp => {
            setFieldOfInterests(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchMasteredLanguages = () => {
        getMasteredLanguages().then(resp => {
            setMasteredLanguages(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchBanks = () => {
        getBanks().then(resp => {
            setBanks(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchProvinces = () => {
        getProvinces().then(resp => {
            setProvinces(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchRegencies = (provinceCode) => {
        getRegencies(provinceCode).then(resp => {
            setRegencies(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchDistricts = (regencyCode) => {
        getDistricts(regencyCode).then(resp => {
            setDistricts(resp);
        }, error => {
            console.log(error);
        })
    }
    const fetchVillages = (districtCode) => {
        getVillages(districtCode).then(resp => {
            setVillages(resp);
        }, error => {
            console.log(error);
        })
    }

    const addOccupation = (event) => {
        setFormData({...formData, occupations: [...formData.occupations, { id: 0 }]});
    }

    const onSelectFileKtp = (event) => {
        delete formData.ktp;
        setFormData({...formData, ktp_file: event.target.files[0]});
    }
    const onSelectFileInsurance = (event) => {
        delete formData.insurance;
        setFormData({...formData, insurance_file: event.target.files[0]});
    }
    const onSelectFileMemberCard = (event) => {
        delete formData.member_card;
        setFormData({...formData, member_card_file: event.target.files[0]});
    }
    const uploadKtp = () => {
        setLoading(true);
        setDone(false);
        setSuccess(false);
        setError(false);

        if (!formData.ktp) {
            uploadFile(formData.ktp_file).then(ktp_file_id => {
                console.log(ktp_file_id);
                if (ktp_file_id) {
                    uploadInsurance(ktp_file_id);
                }
            }, error => {
                console.log(error);
                setDone(true);
                setLoading(false);
                setError(error.data?.message ?? "Unknown error");
            })
        } else {
            uploadInsurance(formData.ktp.id);
        }
    }
    const uploadInsurance = (ktp_file_id) => {
        if (formData.insurance_file && !formData.insurance) {
            uploadFile(formData.insurance_file).then(insurance_file_id => {
                console.log(insurance_file_id);
                if (insurance_file_id) {
                    uploadMemberCard(ktp_file_id, insurance_file_id);
                }
            }, error => {
                console.log(error);
                setDone(true);
                setLoading(false);
                setError(error.data?.message ?? "Unknown error");
            })
        } else {
            uploadMemberCard(ktp_file_id);
        }
    }
    const uploadMemberCard = (ktp_file_id, insurance_file_id) => {
        if (formData.member_card_file && !formData.member_card) {
            uploadFile(formData.member_card_file).then(member_card_file_id => {
                console.log(member_card_file_id);
                if (member_card_file_id) {
                    onSubmit(ktp_file_id, insurance_file_id, member_card_file_id);
                }
            }, error => {
                console.log(error);
                setDone(true);
                setLoading(false);
                setError(error.data?.message ?? "Unknown error");
            })
        } else {
            onSubmit(ktp_file_id, insurance_file_id);
        }
    }

    const uploadCertificates = (relawanId, cleanCertificate = [], index = 0) => {
        const data = { ...formData.occupations[index] };
        data.relawan_id = relawanId;
        data.occupation_id = formData.occupations[index].id;
        delete data.id;

        if (data.certificate) {
            uploadFile(data.certificate).then(resp => {
                console.log(resp);
                if (resp) {
                    data.certificate_file_id = resp;
                    delete data.certificate;
                    delete data.file_public_id;
                    cleanCertificate.push(data);

                    if ((index+1) < formData.occupations.length) {
                        uploadCertificates(relawanId, cleanCertificate, index+1);
                    } else {
                        saveCertificates(cleanCertificate);
                    }
                }
            }, error => {
                console.log(error);
                setDone(true);
                setLoading(false);
                setError(error.data?.message ?? "Unknown error");
            })
        } else {
            delete data.certificate;
            delete data.file_public_id;
            cleanCertificate.push(data);

            if ((index+1) < formData.occupations.length) {
                uploadCertificates(relawanId, cleanCertificate, index+1);
            } else {
                saveCertificates(cleanCertificate);
            }
        }
    }
    const saveCertificates = (certificates) => {
        saveOccupations(certificates).then(resp => {
            console.log("certificates", resp);
            if (resp.length === certificates.length) {
                setDone(true);
                setLoading(false);
                setSuccess(true);
                onSuccess();
            }
        }, error => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(error.data?.message ?? "Unknown error");
        })
    }

    const isValidForm = () => {
        if (formData.method_id === 3 && !formData.phone) {
            return false;
        }
        if ((formData.method_id === 1 || formData.method_id === 2 || formData.method_id === 4) && !formData.email) {
            return false;
        }
        return formData.fullname &&
            tcChecked;
    }

    const onSubmit = () => {
        const data = { ...formData };
        
        delete data.ktp_file;
        delete data.ktp;
        delete data.insurance_file;
        delete data.insurance;
        delete data.member_card_file;
        delete data.member_card;
        delete data.occupations;
        delete data.fieldOfInterests;
        delete data.masteredLanguages;
        delete data.banks;
        delete data.province_id;
        delete data.regency_id;
        delete data.district_id;
        delete data.area_village;
        delete data.relawan_field_of_interest;
        delete data.relawan_mastered_language;
        delete data.relawan_occupation;

        // Adjust the values
        data.email = data.email ? data.email.toLowerCase() : null;
        data.phone = data.phone ? data.phone.replace('+', '') : null;
        data.phone = data.phone ? (data.phone.indexOf('0') === 0 ? '62' + data.phone.substring(1, data.phone.length) : data.phone) : null;

        saveRelawan(data).then(resp => {
            console.log("relawan", resp);
            if ((resp && resp.length > 0) || data.id) {
                setDone(true);
                setLoading(false);
                setSuccess(true);
                onSuccess();

                window.scrollTo(0, 0);
            }
        }, error => {
            console.log(error);
            setDone(true);
            setLoading(false);
            setError(adjustErrorMessage(error.data?.message ?? "Unknown error"));
        })
    }

    const adjustErrorMessage = (message) => {
        if (message) {
            if (message.indexOf('unique_ktp') >= 0) {
                return 'NIK KTP sudah pernah digunakan sebelumnya untuk mendaftar';
            } else if (message.indexOf('unique_email') >= 0) {
                return 'Email sudah pernah digunakan sebelumnya untuk mendaftar';
            } else if (message.indexOf('unique_phone') >= 0) {
                return 'No Telepon sudah pernah digunakan sebelumnya untuk mendaftar';
            }
        }
        return message;
    }

    return (
        <section className="contact-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {/* <LocationPicker
                            containerElement={ <div style={ {height: '100%'} } /> }
                            mapElement={ <div style={ {height: '400px'} } /> }
                            defaultPosition={{
                                lat: -6.229728,
                                lng: 106.6894312
                            }}
                        /> */}
                        { done && success && <div className="contact-form">
                            <h4 className='text-success'>Pendaftaran berhasil!</h4>
                            <p className='text-success' style={{margin:0}}>
                                Periksa email Anda untuk verifikasi dan set password
                            </p>
                        </div> }
                        { done && error && <div className="contact-form">
                            <h4 className='text-danger'>Pendaftaran gagal!</h4>
                            <p className='text-danger' style={{margin:0}}>{error}</p>
                        </div> }
                        { (!done || (done && error)) && <div className="contact-form">
                            { !oriData && <p>Silakan isi form di bawah ini untuk daftar sebagai relawan</p> }
                            
                            <h5>Data Pribadi</h5>
                            <hr/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className='label-sm'>Nama Lengkap (sesuai KTP)</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.fullname}
                                        placeholder="Nama Lengkap (sesuai KTP)"
                                        onChange={(event) => setFormData({...formData, fullname: event.target.value})}
                                    />
                                    <div className='error-container'>
                                        { !formData.fullname && <div className='text-error'>Nama lengkap tidak boleh kosong</div> }
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className='label-sm'>Email Aktif</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        placeholder="Alamat Email"
                                        onChange={(event) => setFormData({...formData, email: event.target.value})}
                                        disabled={formData && (formData.method_id === 1 || formData.method_id === 4)}
                                    />
                                    <div className='error-container'>
                                        { (formData.method_id === 1 || formData.method_id === 2 || formData.method_id === 4) && !formData.email && <div className='text-error'>Email tidak boleh kosong</div> }
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className='label-sm'>No Telepon Aktif</label>
                                    <input
                                        type="number"
                                        name="phone"
                                        value={formData.phone}
                                        placeholder="Nomor Telepon"
                                        onChange={(event) => setFormData({...formData, phone: event.target.value})}
                                        disabled={formData && (formData.method_id === 3)}
                                    />
                                    <div className='error-container'>
                                        { !formData.phone && <div className='text-error'>Nomor telepon tidak boleh kosong</div> }
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12 text-right">
                                    <hr/>
                                    <div className="condition-check" style={{marginTop: '20px'}}>
                                        <input id="terms-conditions" type="checkbox" checked={tcChecked} onChange={(event) => {
                                            if (event.target.checked){
                                                setOpenTc(true);   
                                            } else {
                                                setTcChecked(false);
                                            }
                                        }}/>
                                        <label htmlFor="terms-conditions">
                                            Saya setuju dengan <a href="javascript:void(0)" onClick={() => setOpenTc(true)}>Terms &amp; Conditions</a>
                                        </label>
                                        <input 
                                            type="button" 
                                            name="submit" 
                                            onClick={onSubmit} 
                                            value="Daftar" 
                                            style={{marginLeft: '20px'}}
                                            disabled={!isValidForm()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> }
                    </div>
                </div>
            </div>
            <PopupTC open={openTc} onClose={() => { setOpenTc(false); setTcChecked(false)} } onAgree={() => { setOpenTc(false); setTcChecked(true)} }></PopupTC>
            { openLocationPicker && <PopupLocationPicker 
                title='Lokasi Domisili'
                open={openLocationPicker} 
                defaultPosition={formData.address_lat && formData.address_lon ? {
                    lat: formData.address_lat,
                    lng: formData.address_lon,
                } : null}
                onClose={() => { 
                    setOpenLocationPicker(false);
                }} 
                onFinish={(position) => { 
                    setOpenLocationPicker(false); 
                    setFormData({ ...formData, address_lat: position.lat, address_lon: position.lng });
                }}></PopupLocationPicker> }
        </section>
    );
}

export default FormDetail;
