import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from './FooterHomeOne';
import SiapBergerakHomeOne from './SiapBergerakHomeOne';
import HomeOneHeader from './HomeOneHeader';
import MissionHomeOne from './MissionHomeOne';
import ServicesHomeOne from './ServicesHomeOne';
import EventUpdateHomeOne from '../Home/EventUpdateHomeOne';
import DownloadHomeOne from './DownloadHomeOne';
import DrawerMitra from '../Mobile/DrawerMitra';

function HomeMitra() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <DrawerMitra drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <SiapBergerakHomeOne />
            
            <MissionHomeOne/>
            <ServicesHomeOne/>
            <EventUpdateHomeOne/>
            <DownloadHomeOne/>

            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeMitra;
