import React from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import FooterHomeOne from '../../Home/FooterHomeOne';
import Drawer from '../../Mobile/Drawer';
import HeaderNews from '../../Event/HeaderNews';
import HeroNews from '../../Event/HeroNews';
import Forms from './Forms';

function RevisionRelawan() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews drawer={drawer} action={drawerAction.toggle} />
            <HeroNews
                title="Selamat datang relawan!"
                breadcrumb={[
                    { link: '/', title: 'Beranda' },
                    { link: '/daftar-relawan', title: 'Revisi data relawan' },
                ]}
            />
            <Forms />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default RevisionRelawan;
